/* ============================================ New Signup ========================================================= */

.newLogin {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../../../assests/Onboarding-Background.webp");
    background-size: 100% 100%;
    overflow: hidden;
  }
  .termsConditions {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 15px;
  }
  .termsConditions p {
    font-family: var(--primaryFont);
    font-weight: 400;
    font-size: 15px;
    line-height: 18.15px;
    letter-spacing: 0.5%;
    text-align: center;
    color: var(--black);
  }
  .check{
      width: 18px;
      height: 18px;
      accent-color: #65558F;
  
  }