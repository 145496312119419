.head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.head img {
  object-fit: cover;
  cursor: pointer;
}

.head h1 {
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 500;
  margin-left: 0.5rem;
}

@media only screen and (max-width: 600px) {
  .head img {
    height: 25px;
  }

  .head h1 {
    font-size: 24px;
  }
}