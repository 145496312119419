@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Poppins",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* @media print {
  html,
  body {
    display: none;
  }
} */

body>iframe {
  display: none
}

.singlelinetext {
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1 !important;
  -webkit-box-orient: vertical;
}

.singlelinetextnew {
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1 !important;
  -webkit-box-orient: vertical;
  /* width: 500px; */
}

.singlelinetextlink {
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1 !important;
  -webkit-box-orient: vertical;
  width: 300px;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}

:root {
  --black: #000000;
  --white: #ffffff;
  --oldPrimaryColor: #8B5CF6;
  --primaryColor: #1D37E7;
  --primaryFont: "Inter", sans-serif;
  --secondaryColor: #f4f4f5;
  --textColor: #71717a;
  --emailHighlighter: #71717A;
  --textColorDark: #27272A;
  --emailHighlighter: #9FA4FA;
  --loadingColor: #C7C8FC;
  --loadingIconColor: #0000C0;
  --disableColor: #ebebeb;
  --borderColor: #dbdbdb;
  --dropBorderColor: #E2E8F0;
  --dropColor: #F8FAFC;
  --infoColor: #F5F3FF;
  --approveColor: #EDE9FE;
  --tabHeadColor: #5061F8;
  --verifyColor: #15803D;
  --rejectColor: #c52258;
  --buttonDisableColor: #E4E4E7;
  --lightGreen: #DCFCE7;
  --darkred: #EF4444;
  --lableClr: #1F2937;
  --placeholderColor: #D4D4D8;
  --borderColor2: #E5E7EB;
  --buttonBg: #EEEFEF;
  --buttonBg2: #F3F4F6;
  --buttonClr: #D1D5DB;
  --mildTextColor: #666666;
  --mildTextColor2: #333333;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield !important;
}

/* :root {
  --white: #ffffff;
  --primaryColor: #8b5cf6;
  --primaryFont: "Inter", sans-serif;
  --secondaryColor: #f4f4f5;
  --textColor: #71717a;
  --emailHighlighter: #71717A;
  --textColorDark: #27272A;
  --emailHighlighter: #C4B5FD;
  --loadingColor: #C4B5FD;
  --loadingIconColor: #6D28D9;
  --disableColor: #ebebeb;
  --borderColor: #dbdbdb;
  --dropBorderColor: #E2E8F0;
  --dropColor: #F8FAFC;
  --approveColor: #EDE9FE;
  --tabHeadColor: #A78BFA;
} */
.sectionWidth {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.marginBottom15 {
  margin-bottom: 15px;
}

.marginTop10 {
  margin-top: 10px;
}

.fintsize12 {
  font-size: 12px !important;
}

.marginTop30 {
  margin-top: 30px !important;
}

.marginBottom20 {
  margin-bottom: 20px;
}

.marginLeft20 {
  margin-left: 20px;
}

.width100 {
  width: 100% !important;
}

.marginLeft0 {
  margin-left: 0px !important;
}

.lowerCase {
  text-transform: lowercase !important;
}

.marginBottom35 {
  margin-bottom: 35px;
}

.marginTop20 {
  margin-top: 20px !important;
}

.buttonwidth60 {
  width: 60% !important;
}

.width25 {
  width: 25% !important;
  padding: 5px !important;
}

.mltext {
  margin-left: 7rem !important;
}

.marginTop15 {
  margin-top: 15px;
}

.backGround {
  background-color: #f8f8f8 !important;
}

.paddingLeft100 {
  padding-left: 75px;
}

.paddingLeft50 {
  padding-left: 50px;
}

.paddingLeft90 {
  padding-left: 90px;
}

.paddingRight90 {
  padding-right: 90px;
}

.paddingRight100 {
  padding-right: 75px;
}

.paddingRight50 {
  padding-right: 50px;
}

.paddingTop50 {
  padding-top: 50px;
}

.paddingLeft50 {
  padding-left: 50px;
}

.paddingRight50 {
  padding-right: 50px;
}

.displayFlex {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.alignItemsStart {
  align-items: flex-start !important;
}

.justifyContentStart {
  justify-content: flex-start !important;
}

.justifyContentBetween {
  justify-content: space-between !important;
}

.justifyContentcenter {
  justify-content: center !important;
}

.flexDirectionRow {
  flex-direction: row !important;
}

.paddingXY10 {
  padding-left: 15px;
  padding-right: 15px;
}

.padding15 {
  padding: 15px;
}

.paddingTB6 {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.paddingRL20 {
  padding: 20px 0px !important;
}

.displayHandler {
  display: block;
}

.displayHandlerMob {
  display: none !important;
}

.pointer {
  cursor: pointer !important;
}

.marginBottom0 {
  margin-bottom: 0px !important;
}

.fontWeight500 {
  font-weight: 500 !important;
}

.fontFamilyInter {
  font-family: var(--primaryFont);
}

.height100vh {
  height: 100vh !important;
}

.displayFlex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

/* overlay */
.overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  cursor: pointer;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: var(--primaryColor) !important;
}

@media only screen and (max-width: 600px) {
  .displayHandler {
    display: none !important;
  }

  .displayHandlerMob {
    display: block !important;
  }
}