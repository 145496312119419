.uploadResumeButtonColor {
    background-color: var(--primaryColor);
    color: var(--white);
}

.vendorResumeUploadCompBody {
    background-color: var(--white);
    padding: 25px 35px;
    border-radius: 10px;
    border: 1px solid var(--borderColor);
}

.vendorResumeUploadCompBodyH1 {
    font-size: 16px;
    color: var(--textColor);
}

.vendorResumeUploadCompContentDropFile {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px;
    border: 1px dotted var(--borderColor);
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.vendorResumeUploadCompContentDropFile h2 {
    font-size: 14px;
    font-weight: 500;
    color: var(--textColorDark);
    cursor: pointer;
}

.vendorResumeUploadCompContentDropFile h2 span {
    color: var(--primaryColor);
    margin-left: 5px;
    font-weight: 600;
    cursor: pointer;
}

.vendorResumeUploadCompContentDropFile p {
    font-size: 14px;
    font-weight: 400;
    color: var(--textColor);
}

.vendorResumeUploadCompInfo {
    border-radius: 10px;
    background-color: var(--infoColor);
    padding: 10px 20px;
    display: flex;
    align-items: center;
    color: #8B5CF6;
}

.vendorResumeUploadCompInfo h5 {
    font-size: 16px;
    font-weight: 500;
    color: var(--textColor);
    margin-left: 20px;
}

.resumeStatus {
    display: flex;
    gap: 20px;
    margin-top: 30px;
}

.totalResumeConatiner {
    min-width: 250px;
    padding: 20px;
    background-color: var(--white);
    border-radius: 20px;
}

.totalResumeBody {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 30px;
}

.totalResumeConatinerContentImg {
    padding: 20px;
    border-radius: 22px;
    background-color: #dadaff;
}

.totalResumeConatinerContentImg img {
    height: 20px;
}

.totalResumeConatinerContentImg1 {
    padding: 20px;
    border-radius: 24px;
    background-color: #FEE2E2;
}

.totalResumeConatinerContent p {
    font-size: 16px;
    font-weight: 600;
}

.totalResumeConatinerContent h1 {
    font-size: 28px;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 15px;
}

.totalResumeButton {
    border: 1px solid var(--textColorDark);
    font-size: 12px;
    font-weight: 400;
    padding: 6px 10px;
    border-radius: 8px;
}

.resumeErrorConatiner {
    width: 35%;
    background-color: var(--white);
    border-radius: 8px;
    border: 1px solid var(--borderColor);
}

.resumeErrorConatinerHead {
    background-color: var(--dropColor);
    padding: 15px 20px;
    border-radius: 8px;
    border-bottom: 1px solid var(--dropBorderColor);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.resumeErrorContent {
    padding: 20px;
    list-style: numeric;
    line-height: 35px;
    height: 500px;
    overflow-y: scroll;
}

.resumeErrorContent::-webkit-scrollbar {
    display: none;
}

.resumeErrorButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.resumeErrorButton button {
    background-color: var(--primaryColor);
    color: var(--white);
    font-size: 14px;
    font-weight: 500;
    border: none;
    border-radius: 8px;
    padding: 0px 10px;
}