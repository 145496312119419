.videoResume {
    width: 100%;
    height: 100%;
    padding-bottom: 15px;
    position: relative;
}

.innerVideoResume {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: 1px solid var(--dropBorderColor);
}

.videoResumeHeadLeft {
    display: flex;
    align-items: center;
    justify-content: center;
}

.videoResumeHead {
    padding: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    border-bottom: 1px solid var(--dropBorderColor);
    background-color: var(--dropColor);
}

.videoResumeHead h1 {
    font-size: 14px;
    font-weight: 600;
    margin-left: 10px;
}

.videoResumeDesc {
    width: 100%;
    /* height: 300px; */
    background-color: var(--white);
    border-bottom-left-radius: 8px;
    border-bottom-Right-radius: 8px;
    padding: 30px;
    padding-top: 15px;
    border-radius: 8px;
}

.uploadVedioRes {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border: 1px dashed var(--borderColor);
    margin-top: 25px;
    margin-bottom: 25px;
    border-radius: 8px;
    background-color: #F8FAFC;
}

.uploadVedioRes h2 {
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.3;

}

.uploadVedioRes h3 {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    opacity: 0.3;
}

.videoResumeDesc h1 {
    font-size: 16px;
    font-weight: 500;
    opacity: 0.5;
}

.vedioDelIcon {
    position: absolute;
    top: 4%;
    right: 2%;
    font-size: 22px;
}

.vedioDelIcon:hover {
    color: red;
}

.vedioNotes {
    display: flex;
    align-items: flex-start;
    /* background-color: #F5F3FF; */
    background-color: #E9E9FF;
    /* background-color: var(--loadingColor); */
    padding: 15px;
    border-radius: 8px;
}

.vedioNotes img {
    margin-right: 10px;
    margin-top: 5px;
    color: var(--textColor);
}

.notes h4 {
    opacity: 0.6;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 4px;
}

.notes {
    padding-left: 10px;
}

.notes ol {
    list-style: number;
    opacity: 0.6;
    font-weight: 500;
    font-size: 14px;
    padding-left: 20px;
}

/* overlay */
.vedioOverlay {
    width: 65%;
    height: auto;
    background-color: white;
    display: flex;
    z-index: 10 !important;
    flex-direction: column;
    /* padding: 10px; */
    border-radius: 12px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.vedioResumeButtons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 30px;
    padding-top: 5px;
}

.vedioResumeButtons2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    padding-top: 5px;
}

.vedioResumeButtons1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    padding-top: 5px;
}

.discard {
    padding: 10px 30px;
    border-radius: 8px;
    border: 1px solid var(--borderColor);
    background-color: var(--white);

}

.discardbtn {
    padding: 10px 30px;
    border-radius: 8px;
    border: 1px solid var(--borderColor);
    background-color: var(--white);
    display: flex;
}

.save {
    padding: 10px 30px;
    border-radius: 8px;
    color: var(--white);
    border: 1px solid var(--borderColor);
    background-color: var(--primaryColor);
    margin-left: 20px;
}

.savenew {
    padding: 10px 30px;
    border-radius: 8px;
    color: var(--white);
    border: 1px solid var(--borderColor);
    background-color: var(--primaryColor);
}

.resendbtn {
    padding: 5px 5px;
    border-radius: 8px;
    color: var(--white);
    border: 1px solid var(--borderColor);
    background-color: var(--primaryColor);
    font-size: 12px;
    width: 90px;
}

.resendbtn1 {
    padding: 5px 5px;
    border-radius: 8px;
    color: var(--white);
    border: 1px solid var(--borderColor);
    background-color: var(--disableColor);
    font-size: 12px;
    width: 90px;
    color: #6B7280;

}


.saveSubmit {
    padding: 10px 30px;
    border-radius: 8px;
    color: var(--white);
    border: 1px solid var(--borderColor);
    background-color: var(--primaryColor);
    margin-top: 30px;
    width: 8%;
    /* position: absolute; */
}

.importbtn {
    padding: 10px 20px;
    border-radius: 8px;
    color: var(--white);
    border: 1px solid var(--borderColor);
    background-color: var(--primaryColor);
    margin: 10px 0px;
    width: 3%;
}

.resetbtn {
    padding: 5px 20px;
    border-radius: 8px;
    color: var(--white);
    border: 1px solid var(--borderColor);
    background-color: black;
    margin: 10px 0px;
    width: 3%;
}

.saveSubmit1 {
    padding: 10px 30px;
    border-radius: 8px;
    color: var(--white);
    border: 1px solid var(--borderColor);
    background-color: var(--primaryColor);
    margin-top: 30px;
    width: 10%;
    left: 45%;
    margin-left: 20px;
}

.savesearch {
    padding: 6px 30px;
    border-radius: 8px;
    color: var(--white);
    border: 1px solid var(--borderColor);
    background-color: var(--primaryColor);
    width: 100%;
}

.savesearchfilter {
    padding: 7px 30px;
    border-radius: 8px;
    color: var(--white);
    border: 1px solid var(--borderColor);
    background-color: var(--primaryColor);
}

.cancelsearch {
    padding: 7px 30px;
    border-radius: 8px;
    color: var(--white);
    border: 1px solid var(--borderColor);
    background-color: var(--rejectColor);
}

.divbutton {
    position: absolute;
    right: 2%;
    top: 21%;
}

.savecancel {
    padding: 10px 30px;
    border-radius: 8px;
    color: black;
    border: 1px solid var(--borderColor);
    background-color: white;
    margin-left: 20px;
}

.savedisable {
    padding: 10px 30px;
    border-radius: 8px;
    background-color: var(--loadingColor);
    color: var(--primaryColor);
    background-color: #DBEAFE;
    margin-left: 20px;
    cursor: auto;
}

.saveinterview {
    padding: 4px 15px;
    border-radius: 8px;
    color: var(--white);
    border: 1px solid var(--borderColor);
    background-color: var(--primaryColor);
}

.saveprofile {
    padding: 4px 15px;
    border-radius: 8px;
    color: var(--white);
    border: 1px solid var(--borderColor);
    background-color: var(--verifyColor);
}

.saveinterviewnew {
    padding: 4px 15px;
    border-radius: 8px;
    color: var(--white);
    border: 1px solid var(--borderColor);
    background-color: var(--primaryColor);
    margin: 10px 0px;
}

.adminpersonalbutton {
    padding: 7px 20px;
    border-radius: 8px;
    color: var(--white);
    border: 1px solid var(--borderColor);
    background-color: var(--primaryColor);
    font-size: 15px;
    margin-bottom: 20px;
}

.addmore {
    padding: 5px 30px;
    border-radius: 8px;
    color: var(--white);
    border: 1px solid var(--borderColor);
    background-color: var(--primaryColor);
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
    /* float: right; */
}

.continue {
    padding: 10px 30px;
    border-radius: 8px;
    color: var(--white);
    border: 1px solid var(--borderColor);
    background-color: var(--primaryColor);
}

@media only screen and (max-width: 600px) {
    .vedioResumeButtons {
        flex-direction: column;
        padding: 20px;

    }

    .discard {
        width: 100%;
        padding: 5px 10px;
    }

    .save {
        width: 100%;
        padding: 5px 10px;
        margin-left: 0%;
        margin-top: 15px;
    }

    .vedioOverlay {
        width: 100%;
        height: 100%;
    }

    .innerVideoResumeOverlay {
        height: 100%;
        overflow-y: scroll;
    }
}