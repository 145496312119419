.candidateProgress {
    width: 100%;
    margin-bottom: 30px;
}

.candidateProgressHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}

.candidateProgressHead h1 {
    font-size: 14px;
    font-weight: 700;
}

.TriggerContainer1 {
    /* width: 70%; */
    z-index: 10;
    border-radius: 24px;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);
    background-color: white;
    padding: 10px 25px;
    padding-bottom: 30px;
    overflow-y: scroll;
    max-height: 500px;
    height: fit-content;
}

.TriggerContainernew {
    background-color: white;
    padding: 10px 25px;
    padding-bottom: 30px;
    height: fit-content;
    z-index: 10;
    border-radius: 24px;
}

.TriggerContainer1::-webkit-scrollbar {
    display: none;
}

.TriggerContainer {
    z-index: 10;
    border-radius: 24px;
    background-color: white;
    width: 75%;
}

.TriggerContainerNew {
    z-index: 10;
    border-radius: 24px;
    background-color: white;
    width: 35%;
}

.TriggerContainernew1 {
    z-index: 10;
    border-radius: 24px;
    background-color: white;
    width: 30%;
    padding: 20px;
}

.candidateProgressHead h2 {
    font-size: 14px;
    font-weight: 500;
}

.candidateProgressBar {
    width: 100%;
    height: 10px;
    border-radius: 8px;
    background-color: #F3F4F6;
}

.headingdiv {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.headingdiv h3 {
    font-size: 30px;
    font-weight: 600;
}

.backicon {
    font-size: 30px;
    cursor: pointer;
}


.candidateProgressBarBackgound {
    /* width: 50%; */
    height: 10px;
    border-radius: 8px;
    background-color: var(--primaryColor);
}

@media only screen and (max-width: 600px) {
    .profilePage {
        padding-left: 20px;
        padding-right: 20px;
    }

}