.newLogin {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../../../assests//Onboarding-Background.webp");
  background-size: 100% 100%;
  overflow: hidden;
}

.input1 {
  width: 12%;
  border-radius: 7px;
  text-align: center;
  padding: 9px !important;
}

.inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.resend {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.resend p {
  font-family: var(--primaryFont);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: var(--textColor);
  margin-top: 15px;
}

.resendOTPBtn {
  font-family: var(--primaryFont);
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: var(--mildTextColor2);
  border: 1px solid var(--borderColor2);
  padding: 4px 10px;
  border-radius: 7px;
  margin-top: 15px;
  background-color: var(#F8F8F8);
}

.padding20 {
  padding: 20px 0px;
}

.buttonBg {
  background-color: var(--buttonBg2) !important;
}