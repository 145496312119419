.clientProfileViewHeader {
    padding: 30px 0px;
}

.ClientProfileBackButton {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.clientProfileViewHeader h5 {
    font-size: 15px;
    font-weight: 500;
    color: var(--textColor);
    margin-left: 10px;
}

.clientProfileViewFlex {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 15px 0px;
}

.clientProfileViewFlexLeft {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.clientProfileViewFlexLeftImg img {
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 50%;
}

.clientProfileViewFlexLeftDesc {
    margin-left: 20px;
}

.clientProfileViewFlexLeftDescHead {
    display: flex;
    align-items: center;
}

.pendingApproval {
    font-size: 12px;
    font-weight: 400;
    padding: 5px 10px;
    border-radius: 20px;
    color: #A16207;
    background-color: #FEF9C3;
}

.clientProfileViewFlexLeftDesc h1 {
    font-size: 25px;
    font-weight: 600;
    text-transform: capitalize;
    margin-right: 15px;
}

.clientProfileViewFlexLeftDesc h2 {
    font-size: 14px;
    font-weight: 400;
    color: var(--textColor);
    margin-right: 10px;
    text-transform: capitalize;
}


.clientProfileViewFlexLeftDescRole {
    display: flex;
    margin-bottom: 10px;
    margin-top: 10px;
}

.clientProfileViewFlexLeftDescRole h3 {
    font-size: 14px;
    font-weight: 400;
    color: var(--textColor);
}

.hiredCompany {
    color: var(--primaryColor);
    margin-left: 10px;
    cursor: pointer;
}

.clientProfileViewFlexLeftDescLocation {
    display: flex;
}

.clientProfileViewFlexLeftDescLocation img {
    height: 20px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 5px;
}

.clientProfileViewFlexRight {
    display: flex;
    align-items: flex-start;
    position: relative;
}

.disableProfile {
    font-size: 14px;
    background-color: var(--white);
    padding: 8px 10px;
    border: 1px solid var(--borderColor);
    border-radius: 8px;
    margin-left: 10px;
}

.delete_btn {
    font-size: 14px;
    background-color: #b3003c;
    padding: 8px 10px;
    border: 1px solid var(--borderColor);
    border-radius: 8px;
    margin-left: 10px;
    color: white;
}

.disable_Profile {
    font-size: 14px;
    background-color: lightgray;
    padding: 8px 10px;
    border: 1px solid var(--borderColor);
    border-radius: 8px;
    margin-left: 10px;
    cursor: auto;
}

.editRate {
    font-size: 14px;
    display: flex;
    align-items: center;
    color: var(--white);
    background-color: var(--primaryColor);
    padding: 6px 10px;
    border: 1px solid var(--borderColor);
    border-radius: 8px;
}

.edit_Rate {
    font-size: 14px;
    display: flex;
    align-items: center;
    color: black;
    background-color: white;
    padding: 6px 10px;
    border: 1px solid var(--borderColor);
    border-radius: 8px;
    margin-right: 10px;
    font-weight: 500;
}

.availabilitybtn {
    font-size: 14px;
    display: flex;
    align-items: center;
    color: black;
    background-color: white;
    padding: 6px 10px;
    border: 1px solid var(--borderColor);
    border-radius: 8px;
    margin-right: 10px;
    font-weight: 500;
    background-color: #F3F4F6;
}

.availabilitybtn1 {
    font-size: 14px;
    display: flex;
    align-items: center;
    color: white;
    background-color: white;
    padding: 6px 10px;
    border: 1px solid var(--borderColor);
    border-radius: 8px;
    margin-right: 10px;
    font-weight: 500;
    background-color: green;
}

.edit_Rate:hover {
    background-color: var(--textColorDark);
    color: white;
}

.editRate img {
    margin-right: 10px;
}

.approvalMenu {
    padding: 10px;
    z-index: 10 !important;
    position: absolute;
    background-color: var(--white);
    border: 1px solid var(--borderColor);
    border-radius: 8px;
    top: 120%;
    right: 0%;
    width: 175px;

}

.approvalMenuActive {
    font-size: 14px;
    font-weight: 500;
    padding: 5px 10px;
    border-radius: 8px;
    margin-bottom: 5px;
    cursor: pointer;
}

.approvalMenuActive:hover {
    color: var(--primaryColor);
    background-color: var(--approveColor);
}

.approvalMenuDisable {
    font-size: 14px;
    font-weight: 500;
    padding: 5px 10px;
    margin-top: 5px;
    border-radius: 8px;
    cursor: pointer;
}

.approvalMenuDisable:hover {
    color: #EF4444;
    background-color: #FEE2E2;
}

.approvalMenu1 {
    padding: 10px;
    z-index: 10 !important;
    position: absolute;
    background-color: var(--white);
    border: 1px solid var(--borderColor);
    border-radius: 8px;
    top: 37%;
    right: 7%;

}

.approvalMenu1Active {
    font-size: 14px;
    font-weight: 500;
    padding: 5px 10px;
    border-radius: 8px;
    margin-bottom: 5px;
    cursor: pointer;
    color: var(--primaryColor);
    background-color: var(--approveColor);
}

.approvalMenu1Disable {
    font-size: 14px;
    font-weight: 500;
    padding: 5px 10px;
    margin-top: 5px;
    border-radius: 8px;
    cursor: pointer;
    color: #EF4444;
    background-color: #FEE2E2;
}

/* tab */
.clientViewTab {
    width: 100%;
    display: flex;
    border-bottom: 1px solid var(--borderColor);
}

.clientViewTabInactive {
    padding: 10px;
    font-size: 14px;
    opacity: 0.7;
    cursor: pointer;
}

.clientViewTabActive {
    padding: 10px;
    font-size: 14px;
    border-bottom: 2px solid var(--primaryColor);
    cursor: pointer;
}

/* company details */
.ClientProfileViewCard {
    padding: 20px;
    border-radius: 8px;
    border: 1px solid var(--borderColor);
    margin-top: 40px;
    margin-bottom: 10px;
}

.ClientProfileViewCard h1 {
    font-size: 16px;
    font-weight: 500;
    color: var(--textColor);
    opacity: 0.7;
}

.ClientProfileViewCard h6 {
    font-size: 14px;
    font-weight: 600;
    margin: 15px 15px 15px 0px;
    text-transform: uppercase;
}

.ClientProfileViewCardEdit {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ClientProfileViewCardEdit button {
    padding: 10px 15px;
    background-color: var(--primaryColor);
    color: var(--white);
    font-size: 14px;
    display: flex;
    border: none;
    border-radius: 8px;
    align-items: center;
}

.ClientProfileViewCardEdit img {
    margin-right: 10px;
}

.ClientProfileViewCardBodyTable {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid var(--borderColor);
}

.ClientProfileViewCardBodyTable h2 {
    width: 30%;
    font-size: 14px;
    font-weight: 600;
    padding: 12px 0px;
}

.ClientProfileViewCardBodyTable h3 {
    font-size: 14px;
    font-weight: 400;
    color: var(--textColor);
    padding: 12px 0px;
    /* text-transform: capitalize; */
}

.clientProfileViewHeaderMob {
    display: none;
}


@media only screen and (max-width: 600px) {
    .clientProfileOverview {
        padding-left: 25px;
        padding-right: 25px;
    }

    .clientProfileViewFlex {
        flex-direction: column;
        border: 1px solid var(--borderColor);
        padding: 15px;
        border-radius: 8px;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .ClientProfileViewCard {
        margin-top: 25px;
    }

    .clientViewTabActive {
        font-size: 13px;
        cursor: pointer;
    }

    .clientViewTabInactive {
        font-size: 13px;
        cursor: pointer;
    }

    .ClientProfileViewCardBodyTable h2 {
        width: 50%;
        padding-right: 10px;
    }

    .ClientProfileViewCardEdit button {
        padding: 5px 10px;
    }

    .ClientProfileViewCard h1 {
        width: 70%;
    }

    .clientProfileViewHeader {
        display: none;
    }

    .clientProfileViewHeaderMob {
        display: block;
        padding: 20px 0px;
    }

    .ClientProfileBackButton h5 {
        font-weight: 600;
        margin-left: 5px;
    }

    .clientProfileViewFlexRightMobCandidate {
        width: 100%;
        display: flex;
        /* flex-direction: column; */
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;
        column-gap: 15px;
    }

    .clientProfileViewFlexRightMob {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;
    }

    .editRate {
        width: 90%;
        padding: 6px;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .clientProfileViewFlex {
        background-color: var(--dropColor);
    }

    .disableProfile {
        font-size: 12px;
        width: 48%;
        font-weight: 600;
        margin-left: 0px;
        padding: 8px 10px;
    }

    .disableProfileCandidate {
        font-size: 16px;
        /* width: 10%; */
        font-weight: 600;
        margin-left: 0px;
        padding: 10px 14px;
        border: 1px solid var(--borderColor);
        border-radius: 8px;
    }

    .clientProfileViewFlexRightButtonMob {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid var(--borderColor);
    }

    .clientProfileViewFlexRightButtonMobClient {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;
    }

    .ClientProfileViewCardBodyTable h3 {
        width: 50%;
        overflow: scroll;
    }

    .clientProfileViewFlexLeftDesc {
        width: 100%;
    }

    .clientProfileViewFlexLeft {
        width: 100%;
    }

    .clientProfileViewFlexLeftDescHead {
        flex-direction: column;
        align-items: flex-start;
        position: relative;
    }

    .clientProfileViewFlexLeftDescHead img {
        position: absolute;
        right: 0%;
        top: 10%;
    }

    .clientProfileViewFlexLeftDescRole {
        display: flex;
        margin-bottom: 0px;
    }

    .clientProfileViewFlexLeftDescLocation {
        flex-direction: column;
    }

    .clientProfileViewFlexLeftDesc h2 {
        font-size: 11px;
    }

    .pendingApproval {
        margin-top: 5px;
        margin-bottom: 15px;
    }

    .clientProfileViewFlexLeftDesc h1 {
        font-size: 20px;
    }

    .clientViewTab {
        width: 321px;
    }

    .clientProfileViewFlexLeftImg img {
        width: 120px;
        height: 80px;
        object-fit: cover;
    }
}