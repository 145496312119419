.professionalDetails {
    width: 100%;
    height: 100%;
    padding-bottom: 15px;
    position: relative;
}

.innerprofessionalDetails {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: 1px solid var(--dropBorderColor);
}

.professionalDetailsHeadLeft {
    display: flex;
    align-items: center;
    justify-content: center;
}

.professionalDetailsHead {
    padding: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    border: 1px solid var(--dropBorderColor);
    background-color: var(--dropColor);
}

.bottomBorder {
    padding: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    background-color: var(--dropColor);
}


.professionalDetails h1 {
    font-size: 14px;
    font-weight: 600;
    margin-left: 10px;
}

.professionalDetailsLeftIcon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.professionalDetailsLeftIconSvg {
    margin-right: 10px;
    height: 18px;
}

.professionalDetailsDesc {
    width: 100%;
    /* height: 300px; */
    background-color: var(--white);
    border-bottom-left-radius: 8px;
    border-bottom-Right-radius: 8px;
    padding: 30px;
}

.professionalDetailsDesc h1 {
    font-size: 15px;
    color: var(--textColor);
    margin-bottom: 20px;
    margin-left: 0;
}

.professionalDetailsDesc h2 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 3px;
    margin-bottom: 10px;
}

.professionalDetailsDesc h3 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 3px;
    /* margin-bottom: 10px; */
}

.professionalDetailsDesc h4 {
    font-size: 14px;
    font-weight: 600;
    color: var(--textColor);
    margin-bottom: 3px;
    opacity: 0.7;
    /* margin-bottom: 10px; */
}

.professionalDetailsDesc h4 {
    font-size: 14px;
    font-weight: 600;
    color: var(--textColor);
    margin-bottom: 3px;
    opacity: 0.7;
    /* margin-bottom: 10px; */
}

.professionalDetailsDesc h5 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 3px;
    margin-bottom: 20px;
}

.professionalDetailsDesc h6 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 3px;
}

.professionalDetailsDescSkills {
    font-weight: 500;
}

/* overLay */
.professionalDetailsOverlay {
    width: 65%;
    /* height: 80%; */
    background-color: white;
    display: flex;
    z-index: 10 !important;
    flex-direction: column;
    border-radius: 12px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow-y: scroll;
}

.professionalDetailsOverlay2 {
    width: 65%;
    height: 90%;
    background-color: white;
    display: flex;
    z-index: 10 !important;
    flex-direction: column;
    border-radius: 12px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow-y: scroll;
}

.professionalDetailsOverlay2new {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    padding-bottom: 0px;
}

.professionalDetailsOverlay2new h6 {
    font-size: 16px;
    font-weight: 600;
    opacity: 0.5;
}

.professionalDetailsOverlay2new h1 {
    font-size: 16px;
    font-weight: 700;
}

.professionalDetailsOverlay2new {
    font-size: 13px;
}

.professionalDetailsOverlay1 {
    width: 65%;
    background-color: white;
    display: flex;
    z-index: 10 !important;
    flex-direction: column;
    border-radius: 12px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow-y: scroll;
}


.professionalDetailsOverlay h6 {
    font-size: 16px;
    font-weight: 600;
    padding: 30px;
    opacity: 0.5;
    padding-bottom: 0px
}

.professionalDetailsOverlay2 h6 {
    font-size: 16px;
    font-weight: 600;
    padding: 30px;
    opacity: 0.5;
    padding-bottom: 0px
}

.professionalDetailsOverlay1 h6 {
    font-size: 16px;
    font-weight: 600;
    padding: 30px;
    opacity: 0.5;
}

.professionalDetailsOverlay2 h6 {
    font-size: 16px;
    font-weight: 600;
    padding: 30px;
    opacity: 0.5;
}

.checkdiv {
    /* width: 100%; */
    padding: 10px;
    border-radius: 8px;
    margin-top: 5px;
    border: 1px solid var(--borderColor);
}

.professionalDetailsOverlayFlex {
    display: flex;
    align-items: flex-start;
    padding: 30px;
    padding-top: 5px;
}

.professionalDetailsOverlayLeft {
    width: 38%;
    margin-right: 2%;
}


.professionalDetailsOverlayLeft h2 {
    font-size: 14px;
    font-weight: 600;
    margin-top: 20px;
}

.professionalDetailsOverlayLeft input {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    margin-top: 5px;
    border: 1px solid var(--borderColor);
}

.professionalDetailsOverlayLeft select {
    width: 130px;
    margin-right: 20px;
    padding: 10px;
    border-radius: 8px;
    margin-top: 5px;
    border: 1px solid var(--borderColor);
}

.grossSalary {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.professionalDetailsOverlayRight {
    width: 58%;
    margin-left: 2%;
}

.professionalDetailsOverlayRight h2 {
    font-size: 14px;
    font-weight: 600;
    margin-top: 20px;
}

.professionalDetailsOverlayRight input {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    margin-top: 5px;
    border: 1px solid var(--borderColor);
}

.yearsActive {
    display: flex;
    align-items: center;
    gap: 20px;
}

.text {
    width: 100%;
    height: 140px;
    padding: 10px;
    border-radius: 8px;
    margin-top: 5px;
    border: 1px solid var(--borderColor);
}

.textDesc {
    width: 100%;
    justify-content: space-between;
    display: flex;

}

.textDesc h5 {
    font-size: 14px;
    font-weight: 500;
    opacity: 0.4;
    margin-top: 20px;
}

.AddMore {
    width: 100%;
    height: 100%;
    padding: 0px 30px;
    padding-bottom: 30px;
}

.Add_More {
    padding: 0px 30px;
    padding-bottom: 30px;
}

.Add_More button {
    width: 100%;
    background-color: var(--addMore);
    border: 1px solid var(--loadingColor);
    padding: 8px 15px;
    border-radius: 8px;
    color: var(--primaryColor);
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: flex-start;

}

.AddMore button {
    width: 100%;
    background-color: var(--addMore);
    border: 1px solid var(--loadingColor);
    padding: 8px 15px;
    border-radius: 8px;
    color: var(--primaryColor);
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.AddMore h3 {
    margin-left: 20px;
}

@media only screen and (max-width: 600px) {
    .professionalDetailsOverlay {
        width: 100%;
        height: 100%;
    }

    .professionalDetailsOverlay h6 {
        padding: 0px 20px;
    }

    .innerprofessionalDetailsOverlay {
        height: 15%;
        overflow-y: scroll;
    }

    .professionalDetailsOverlayFlex {
        height: 100%;
        padding: 20px;
        flex-direction: column;
        overflow-y: scroll;
    }

    .professionalDetailsOverlayLeft {
        width: 100%;
    }

    .professionalDetailsOverlayRight {
        width: 100%;
        margin-left: 0px;
    }

}