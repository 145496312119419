.adminContractCard {
    display: flex;
    align-items: center;
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    grid-gap: 20px;
}

.addContractCard {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border: 2px dashed #E4E4E7;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
}

.addContractCard p {
    color: #71717A;
    font-size: 15px;
    font-weight: 500;

}