.contractTableHead {
    padding: 10px 0px;
    text-align: start;
    font-weight: 400;
    font-size: 13px;
    opacity: 0.7;

    border-bottom: 1px solid var(--borderColor);
}

.contactimg {
    width: 30px;
    height: 30px;
    object-fit: cover;
}

.contractInnerTable {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: 1px solid var(--borderColor);
    margin-top: 20px;
    padding-left: 15px;
    padding-Right: 15px;
    margin-bottom: 30px;
    background-color: var(--white);
}

.contractTableRow {
    padding-left: 20px;
}

.contractTableRow td {
    padding: 15px 0px;
}

.Contract {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
}

@media only screen and (max-width: 600px) {
    .Contract {
        grid-template-columns: repeat(1, 1fr);
    }

    .contractInnerTable {
        /* width: 1000px; */
        overflow-x: scroll;

    }

    .table {
        width: 1000px;
        overflow-x: scroll;
    }
}