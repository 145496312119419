@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



#overlay {
  position: fixed;
  /* Sit on top of the page content */
  /* Hidden by default */
  width: 100vw;
  /* Full width (cover the whole page) */
  height: 100vh;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Black background with opacity */
  z-index: 2;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  /* Add a pointer on hover */
}

#overlayTransparent {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 2;
  cursor: pointer;
}

.navButton {
  display: none;
}

/* under constructions */
.underConstruction {
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.underConstruction_logo {
  width: 200px;
}

.underConstruction button {
  background-color: var(--primaryColor) !important;
  color: var(--white) !important;
  padding: 10px !important;
  margin-top: 20px !important;
  border-radius: 10px !important;
}

/*  */
@media only screen and (max-width: 600px) {
  .navButton {
    display: block;
    padding: 10px;
    position: absolute;
    top: 2.5%;
    right: 5%;
    z-index: 5 !important;
    background-color: var(--primaryColor);
    color: var(--white);
    border-radius: 8px;
    border: none;
  }
}