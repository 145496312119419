.height {
  height: 100% !important;
}

.align {
  align-items: flex-start !important;
}

.paddingTop {
  padding-top: 130px;
}

.marginTop {
  margin-top: 60px;
  margin-bottom: 60px;
}

.width80 {
  width: 80%;
}

.width50 {
  width: 50% !important;
}

.width60 {
  width: 60%;
}

.width90 {
  width: 93%;
}

.width45 {
  width: 45% !important;
}

.relative {
  position: relative;
}

.absolute1 {
  position: absolute;
  top: 0;
  left: 0%;
  z-index: 10;
  border-radius: 20px;
}

.mergeLabel {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.contentInput {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contentInput select {
  width: 40%;
}

.selectBorder {
  border-top: 1px solid var(--borderColor);
  border-bottom: 1px solid var(--borderColor);
  border-left: 1px solid var(--borderColor);
  padding: 10px;
  border-radius: 9px 0px 0px 9px;
  margin-top: 10px;
}

.inputBorder {
  border-top: 1px solid var(--borderColor2);
  border-bottom: 1px solid var(--borderColor2);
  border-right: 1px solid var(--borderColor2);
  border-left: 1px solid var(--borderColor2);
  padding: 8.5px;
  border-radius: 0px 9px 9px 0px;
  margin-top: 10px;
}

.marginTo15 {
  margin-top: 15px;
}

.loc {
  width: 100%;
  color: var(--primaryColor);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  gap: 5px;
}

.loc p {
  font-family: var(--primaryFont);
  font-weight: 400;
  font-size: 15px;
  line-height: 18.15px;
}

.cursor {
  cursor: pointer;
}

.bgc {
  background-color: var(--primaryColor) !important;
  color: var(--white);
}

.verifyButton {
  padding: 2px 10px;
  background-color: var(--primaryColor);
  color: var(--white);
  border-radius: 7px;
  font-family: var(--primaryFont);
  font-size: 14px;
}

.verifyButtondisable {
  padding: 2px 10px;
  background-color: var(--disableColor);
  color: var(--mildTextColor2);
  border-radius: 7px;
  font-family: var(--primaryFont);
  font-size: 14px;
  cursor: not-allowed;
}

.viewOverlay {
  /* width: 50%;
    height: 90%; */
  width: 100%;
  height: 118vh;
  /* position: absolute; */
  /* height: 600px; */
  background-color: rgba(0, 0, 0, 0.2);
  /* height: 80vh; */
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  z-index: 10;
}

.verifyPopup {
  width: 28%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  box-shadow: 1px 4px 4px 0px #0000001A;
  border-radius: 20px;
}

.justify-conten-end {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end !important;
}

@media only screen and (max-width: 600px) {
  .fontSize {
    font-size: 20px;
    line-height: 24px;
  }

  .mergeLabel {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .width50 {
    width: 100% !important;
  }

  .width60 {
    width: 100% !important;
  }

  .verifyPopup {
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    box-shadow: 1px 4px 4px 0px #0000001A;
    border-radius: 20px;
    margin-top: 45rem;
  }
}