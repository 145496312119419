.AdminClientProfileComp {
    width: 100%;
    height: 100%;
    background-color: var(--white);
    margin-bottom: 30px;
    overflow-x: scroll;
}

.AdminClientProfileComp::-webkit-scrollbar {
    display: none;
}

.customerView {
    padding-left: 0px;
    padding-right: 0px;
}

.shortListButtonLeft {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.processingstaus {
    color: #6B7280;
    background-color: #80808033;
    text-align: center;
    font-size: 12px;
}

.AdminClientProfileCompSearch {
    padding: 10px;
    position: relative;
    /* border: 1px solid var(--borderColor); */
    border-radius: 8px 8px 0px 0px;

}

.profileselect {
    /* width: 100%;
    padding: 10px;
    border-radius: 8px;
    margin-top: 5px;
    border: 1px solid var(--borderColor); */

    width: 100%;
    padding: 10px;
    border-radius: 8px;
    margin-top: 5px;
    border: 1px solid var(--borderColor);
    /* margin-left: 234px; */
}

.selectdiv {
    width: 100%;
    gap: 2rem;
    display: flex;
}

.AdminClientProfileCompSearch input {
    width: 100%;
    padding: 10px;
    padding-left: 50px;
    border-radius: 8px;
    border: 1px solid var(--borderColor);
}

/* .AdminClientProfileCompSearch img {
    position: absolute;
    top: 25%;
    left: 2%;
} */

/* table */

.AdminClientTable {
    width: 100%;
    height: 100%;
    border: 1px solid var(--borderColor);
    border-top: none;
    border-radius: 8px;
}

.AdminTableHead {
    border-bottom: 1px solid var(--borderColor);
}

.AdminTableHead th {
    padding: 15px 15px;
    text-align: start;
    font-weight: 400;
    font-size: 14px;
    opacity: 0.5;
}

.tableName {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.tableName h1 {
    font-size: 14px;
    font-weight: 400;
    margin-left: 10px;
}

.tableName img {
    height: 32px;
    width: 32px;
    object-fit: cover;
    border-radius: 50%;
}

.adminTableRow td {
    padding: 15px;

}

.tableLocation {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.tableLocation img {
    height: 25px;
    object-fit: cover;
    border-radius: 50%;
}

.tableLocation h1 {
    font-size: 14px;
    font-weight: 400;
    margin-left: 10px;
}

.AdminClientTable h1 {
    font-size: 14px;
    font-weight: 400;
    /* text-transform: capitalize; */
}

.AdminClientTable1 h1 {
    font-size: 12px;
    font-weight: 400;
}

.AdminClientTable1 h1 {
    font-size: 12px;
    font-weight: 400;
    /* text-transform: capitalize; */
}


.AdminClientTable1 h2 {
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 400;
    width: 180px;
}


.viewButton {
    padding: 5px 15px;
    color: var(--white);
    border: none;
    border-radius: 8px;
    background-color: var(--primaryColor);
}

/* status */
.status {
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    padding: 3px;
    border-radius: 20px;
}

.reserving {
    color: #A16207;
    background-color: #FEF9C3;
    text-align: center;
    font-size: 12px;
}


.hiringActive {
    color: #15803D;
    background-color: #DCFCE7;
    text-align: center;
    font-size: 12px;
}

.contracted {
    color: #3B82F6;
    background-color: #DBEAFE;
    text-align: center;
    font-size: 12px;
}

.rejectstaus {
    color: #EF4444;
    background-color: #FEE2E2;
    text-align: center;
    font-size: 12px;
}

.benched {
    color: #6B7280;
    background-color: #F3F4F6;
    text-align: center;
    font-size: 12px;
}

/* tablePagination */
/* .tablePagination {
    width: 100%;
    padding: 40px;
    border: 1px solid var(--borderColor);
    border-top: none;
    border-radius: 0px 0px 8px 8px;
} */


/* page css for adminprofilepage */
.shortListButton {
    width: 100%;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: space-between;

}

.card {
    padding: 20px;
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 30%;
    text-align: center;
}

.div-card {
    background-color: var(--loadingColor);
    color: var(--primaryColor);
    font-weight: 600;
}


.shortListButton1 {
    width: 100%;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: flex-start;

}


.shortListButtonDisable {
    width: 200px;
    padding: 8px;
    border: 1px solid var(--borderColor);
    margin-right: 15px;
    border-radius: 8px;
}

.shortListButtonActive {
    width: 200px;
    padding: 8px;
    border: 1px solid var(--borderColor);
    margin-right: 15px;
    border-radius: 8px;
    background-color: var(--borderColor);
}

nav {
    display: flex;
    justify-content: center;
}

.pagination {
    list-style: none;
    display: flex;
    padding: 0;
}

.page-item {
    margin: 0 5px;
}

.dotted-item {
    font-size: 20px;
    font-weight: bold;
}

.page-link {
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 10px;
    text-decoration: none;
    color: #333;
}

.page-link:hover {
    background-color: var(--primaryColor);
    color: #fff;
}

.active {
    background-color: var(--primaryColor);
    color: #fff;
    border-radius: 10px;
}

.active .page-link {
    color: white;
    font-weight: bold;
}

.disabled {
    pointer-events: none;
    cursor: not-allowed;
    background-color: #f0f0f0;
    /* border: 1px solid #ccc; */
    color: #999;
    border-radius: 10px;
}

@media only screen and (max-width: 600px) {
    .adminProfile {
        padding-left: 25px;
        padding-right: 25px;
    }

    .shortListButton {
        width: 100%;
        flex-direction: column;
    }

    .shortListButtonDisable {
        width: 48%;
        margin-right: 0px;
        padding: 5px;
    }

    .shortListButtonActive {
        width: 48%;
        margin-right: 0px;
        padding: 5px;
    }

    .selectdiv {
        display: none;
    }

    .shortListButtonLeft {
        width: 100%;
    }

    .shortListButton1 {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .AdminClientTable {
        width: 900px;
    }
}