.forgetComp {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
}

/* .clientForgetComp {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
} */
.errorstatus {
    font-size: 12px;
    color: red !important;
    font-weight: 600;
    margin-top: 10px;
}

.clientForgetCompInner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.clientForgetCompBody {
    width: 100%;
}

/* ========================= Email ======================== */
.clientForgetCompBodyEmail {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.clientForgetCompBodyEmail h4 {
    font-size: 0.85rem;
    font-weight: 700;
    margin-bottom: 8px;
    margin-left: 3px;
}

.clientForgetCompBodyEmail input {
    width: 100%;
    height: 45px;
    padding: 10px;
    /* border: 1px solid var(--secondaryColor); */
    border: 1px solid var(--borderColor);
    border-radius: 5px;
}



/* ========================= Button ======================== */
.clientForgetCompBodyButton {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.clientForgetCompBodyButtonEnable {
    background-color: var(--primaryColor);
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    color: var(--white);
    margin-top: 1.2rem;
}

.clientForgetCompBodyButtonDisable {
    background-color: var(--disableColor);
    color: var(--textColor);
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    margin-top: 1.2rem;
}


.clientForgetCompBodyButton h4 {
    color: var(--primaryColor);
    margin-top: 1rem;
    font-size: 0.8rem;
    font-weight: 700;
}

/*============================ Forgot Password Verification ============================= */

.forgotVerificationSuccess {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.forgotVerificationInner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.forgotVerificationInnerHead {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;

}

.forgotVerificationInner p {
    font-size: 13px;
    font-weight: normal;
    line-height: 20px;
    color: var(--textColor);
}

.forgotVerificationInner p span {
    color: var(--textColor);
    font-weight: bold;
}

.forgotVerificationInner p[title] {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: var(--textColor);
}

.forgotemailBox {
    border-top: 1px solid var(--borderColor);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding: 20px 0px;
    margin-top: 15px;
}

.forgotemailBox button {
    border: 1px solid var(--borderColor);
    border-radius: 5px;
    padding: 8px 12px;
    color: var(--textColorDark);
    font-weight: 600;
    font-size: 15px;
    line-height: 18.15px;
}

.forgotVerificationBottom {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.forgotVerificationBottom p {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: var(--textColor);
}

.forgotVerificationBottom p[title] {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: var(--textColor);
}

p[title] span {
    color: var(--primaryColor);
    cursor: pointer;
}

/* ========================= Reset Password ========================== */
.resetPassword {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
}

.resetPasswordCompInner {
    width: 100%;
}

/* ================ Body ============ */
.resetPasswordCompBody {
    width: 100%;
}

/* ========================= Email ======================== */
.resetPasswordCompBodyPassword {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.resetPasswordCompBodyPassword h4 {
    font-size: 0.8rem;
    font-weight: 700;
    margin-bottom: 8px;
    margin-left: 3px;
}

.resetPasswordCompBodyPasswordInput {
    width: 100%;
    position: relative;
}

.resetPasswordCompBodyPasswordInput input {
    width: 300px;
    height: 45px;
    padding: 10px;
    border: 1px solid var(--borderColor);
    border-radius: 5px;
}

/* ========================= Password ======================== */
.resetPasswordCompBodyConfirmPassword {
    width: 100%;
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.resetPasswordCompBodyConfirmPasswordLabel {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.resetPasswordCompBodyConfirmPassword h4 {
    font-size: 0.8rem;
    font-weight: 700;
    margin-bottom: 5px;
    margin-left: 3px;
}

.resetPasswordCompBodyConfirmPassword h5 {
    font-size: 0.8rem;
    color: var(--primaryColor);
    margin-bottom: 8px;
    margin-left: 3px;
}

.resetPasswordCompBodyConfirmPasswordInput {
    width: 100%;
    position: relative;
}

.resetPasswordCompBodyConfirmPasswordInput input {
    width: 100%;
    height: 45px;
    border: 1px solid var(--borderColor);
    border-radius: 5px;
    padding: 10px;
}

/* ========================= Button ======================== */
.resetPasswordCompBodyButton {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: var(--primaryColor); */
}

.resetPasswordCompBodyButtonEnable {
    background-color: var(--primaryColor);
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    color: white;
}

.resetPasswordCompBodyButtonDisable {
    background-color: var(--disableColor);
    color: var(--textColor);
    width: 100%;
    padding: 10px;
    border-radius: 10px;
}

.resetPasswordCompBodyButtonLoading {
    background-color: var(--buttonLoading);
    color: var(--white);
    width: 100%;
    padding: 10px;
    border-radius: 10px;
}

@media only screen and (max-width: 600px) {
    .forgotVerificationInner h1 {
        font-size: 20px;
    }

    .forgotVerificationInner p {
        text-align: center;
    }

    .forgotemailBox button {
        font-size: 13px;
    }

    .forgotVerificationBottom h2 {
        font-size: 22px;
    }

    .forgotVerificationBottom p {
        font-size: 12px;
    }
}