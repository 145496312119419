.clientInterview {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    padding-top: 5px;
}

.clientInterviewInner {
    /* width: 73%; */
    display: flex;
    flex-direction: column;
}

.bookmarkicondiv {
    font-size: 30px;
    margin-top: 10px;
    color: #f59e0b;
    cursor: pointer;
}

.normalloading {
    color: var(--primaryColor);
    font-size: 44px;
    text-align: center;
    transform: rotate(180deg);
    animation: rotate 0.3s infinite;
    margin-top: 10px;
}
.normal_loading{
    font-size: 20px;
}

.bookmarkicondiv1 {
    font-size: 50px;
    margin-top: 10px;
    cursor: pointer;
}

.clientInterviewInner h1 {
    font-size: 16px;
    font-weight: 600;
}

.clientInterviewInner .clientCarts {
    display: flex;
    flex-direction: column;
    /* display: grid;
        grid-template-columns: repeat(1, 2fr); */
    grid-gap: 20px;
    margin-bottom: 10px;
}

.clientCarts1 {
    width: 515px;
    display: flex;
    flex-direction: column;
    margin-right: 15px;
    padding: 15px;
    border-radius: 10px;
    background-color: var(--white);
    border: 1px solid var(--borderColor);
    cursor: pointer;

}

.candidateCartProfile {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.candidateCartProfile .candidateCartProfile1 {

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.candidateImage {
    border-radius: 100%;
    margin-right: 15px;
}

.candidateImage img {
    border-radius: 100%;
    height: 50px;
    width: 50px;
    object-fit: cover;
}


.candidateCartName {
    display: flex;
    flex-direction: column;
}

.candidateCartName h3 {
    font-size: 18px;
    font-weight: 600;
}

.candidateCartName h5 {
    font-size: 12px;
    font-weight: 400;
    margin-top: 4px;
}

.candidateHours {
    height: 100%;
}

.candidateHours h2 {
    font-size: 14px;
    font-weight: 400;
}

.candidateCartSkills {
    width: 100%;
    height: 85px;
    overflow-y: hidden;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 10px 0px;
}

.candidateCartSkillsH4 {
    margin: 5px 10px;
    padding: 2px 7px;
    margin-left: 0px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    background-color: #F4F4F5;
    background-color: var(--disableColor);
    font-size: 14px;
    font-weight: 400;
    color: var(--textColorDark);

}

.candidateCartSkillsBgColor {
    margin: 5px 10px;
    padding: 2px 7px;
    margin-left: 0px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: var(--primaryColor);
    background-color: #E9E9FF;
}

.candidateCartSkills svg {
    margin-right: 5px;
    object-fit: cover;
    height: 20px;
    width: 20px;
}

.candidateCartExperience {
    width: auto;
    display: flex;
    flex-direction: column;
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    grid-row-gap: 10px;
    grid-column-gap: 20px;
}

.candidateCartExp {
    width: 100%;
}

.candidateCartExp p {
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: var(--emailHighLighter);


}

.candidateCartExp p img {
    margin-right: 6px;
}

.candidateCartButton {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 5px;
}

.cartbtnimg {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
}

.cartbtnimg img {
    padding: 10px;
    border-radius: 7px;
    border: 1px solid var(--borderColor);
    cursor: pointer;
    margin-top: 10px;
}

.cartbtnimgbutton1 {
    margin-top: 10px;
    width: 100%;
    margin-left: 15px;
    display: flex;
    padding: 8px;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: none;
    outline: none;
    background-color: var(--primaryColor);
    color: white;

}

.cartbtnimgbutton1disable {
    margin-top: 10px;
    width: 100%;
    margin-left: 15px;
    display: flex;
    padding: 8px;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: none;
    outline: none;
    background-color: var(--loadingColor) !important;
    color: white;

}

.cartbtnimgbutton1disablenew {
    margin-top: 10px;
    width: 100%;
    display: flex;
    padding: 8px;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: none;
    outline: none;
    background-color: var(--loadingColor) !important;
    color: white;

}

.cartbtnimgbutton1disablenew {
    margin-top: 10px;
    width: 40%;
    display: flex;
    padding: 8px;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: none;
    outline: none;
    background-color: var(--loadingColor) !important;
    color: white;

}

.cartbtnimgbutton2 {
    margin-top: 10px;
    width: 100%;
    margin-left: 15px;
    display: flex;
    padding: 8px;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
    color: var(--textColorDark);
    background-color: var(--white);
    border: 1px solid var(--borderColor);

}

@media only screen and (max-width: 600px) {
    .clientCarts1 {
        width: 280px;
    }

    .candidateCartExperience {
        grid-template-columns: repeat(1, 1fr);
        margin-top: 10px;
        /* margin-bottom: 10px; */
    }

    .cartbtnimg {
        height: 100%;
        flex-wrap: wrap;
    }

    .cartbtnimgbutton1 {
        padding: 5px;
        width: 80%;
        margin-left: 0px;
    }

    .cartbtnimgbutton2 {
        padding: 5px;
        margin-left: 0%;
    }

    .candidateCartProfile {
        flex-wrap: wrap;
    }
}