.courses {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0px;
}

.subCourses {
  width: 88%;
}

.subCourses h1 {
  font-family: var(--primaryFont);
  font-size: 48px;
  font-weight: 500;
  line-height: 58.09px;
  letter-spacing: 0.005em;
  text-align: left;
  color: #18181b;
}

.subCourses p {
  font-family: var(--primaryFont);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.005em;
  text-align: left;
  color: #71717a;
}

.courseFlex {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 25px 10px;
}

.searchIcon {
  display: flex;
  align-items: center !important;
  font-size: 22px;
  color: #71717a;
}

.searchIcon ::placeholder {
  font-family: var(--primaryFont);
  font-size: 24px;
  font-weight: 400;
  line-height: 29.05px;
  letter-spacing: 0.005em;
  text-align: left;
  color: #27272a !important;
}

.searchIcon input {
  border: none !important;
  outline: none !important;
  margin-left: 10px;
  margin-top: 5px;
}

.buttonSearch button {
  padding: 5px 10px;
  background-color: #f3f4f6;
  color: #d1d5db;
  font-family: var(--primaryFont);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.005em;
  text-align: center;
  border-radius: 5px;
}

.dropDowns {
  display: flex;
  align-items: center;
  gap: 20px;
}

.dropdown {
  padding: 10px 15px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 7px;
  color: #1f2937 !important;
  border: 1px solid var(--Colors-Zinc-200, #e4e4e7);
}

.dropdown p {
  font-family: var(--primaryFont);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.005em;
  text-align: left;
  margin-right: 10px;
  color: #1f2937;
}

.clearall {
  font-family: var(--primaryFont);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.005em;
  text-align: center;
  color: #8b5cf6 !important;
}

.courseGrid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 30px;
}

.courseCard {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 2.71px 2.71px 0px #00000040;

}

.courseCard h3 {
  font-family: var(--primaryFont);
  font-size: 14.9px;
  font-weight: 600;
  line-height: 18.04px;
  text-align: left;
  margin-top: 15px;
}

.courseDetails {
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  padding-bottom: 15px;
}

.details {
  width: 100%;
  display: flex;
  align-items: center;
}

.details p {
  font-family: var(--primaryFont);
  font-size: 9.48px;
  font-weight: 600;
  line-height: 12.94px;
  text-align: left;
}

.ratingandBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  padding: 2px;
}

.ratingandBtn p {
  display: flex;
  align-items: center;
  font-family: var(--primaryFont);
  font-size: 10.84px;
  font-weight: 700;
  line-height: 13.12px;
  text-align: left;
  color: var(--primaryColor);
  gap: 3px;
}

.ratingandBtn button {
  padding: 10px 50px;
  background-color: var(--primaryColor);
  color: white;
  font-family: var(--primaryFont);
  font-size: 10.84px;
  font-weight: 600;
  line-height: 14.78px;
  text-align: left;
  border-radius: 8px;
}

.bgColor {
  background-color: #FAFBFB;
}