.height {
  height: auto !important;
}

.align {
  align-items: flex-start !important;
}

.paddingTop {
  padding-top: 120px;

}

.marginTop {
  margin-top: 60px;
  margin-bottom: 60px;
}

.width {
  width: 80%;
}

.width50 {
  width: 50%;
}

.width60 {
  width: 60%;
}

.width90 {
  width: 93%;
}

.mergeLabel {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.contentInput {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contentInput select {
  width: 40%;
}

/* .selectBorder {
  border-top: 2px solid var(--borderColor2);
  border-bottom: 2px solid var(--borderColor2);
  border-left: 2px solid var(--borderColor2);
  padding: 11px;
  border-radius: 9px 0px 0px 9px;
  margin-top: 15px;
} */

/* .inputBorder {
  border-top: 2px solid var(--borderColor2);
  border-bottom: 2px solid var(--borderColor2);
  border-right: 2px solid var(--borderColor2);
  border-left: none;
  padding: 8px;
  border-radius: 0px 9px 9px 0px;
  margin-top: 10px;
} */

.marginTo15 {
  margin-top: 15px;
}

.flexColClass {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.loc {
  width: 100%;
  color: var(--primaryColor);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  gap: 5px;
}

.loc p {
  font-family: var(--primaryFont);
  font-weight: 400;
  font-size: 15px;
  line-height: 18.15px;
}

.cursor {
  cursor: pointer;
}

.bgc {
  background-color: var(--primaryColor) !important;
  color: var(--white);
}

.verifyButton {
  padding: 2px 10px;
  background-color: var(--primaryColor);
  color: var(--white);
  border-radius: 7px;
  font-family: var(--primaryFont);
  font-size: 14px;

}

.verifyButtondisable {
  padding: 2px 10px;
  background-color: var(--disableColor);
  color: var(--mildTextColor2);
  border-radius: 7px;
  font-family: var(--primaryFont);
  font-size: 14px;
  cursor: not-allowed;
}


@media only screen and (max-width: 600px) {
  .fontSize {
    font-size: 20px;
    line-height: 24px;
  }

  .mergeLabel {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .width50 {
    width: 100% !important;
  }

  .width60 {
    width: 100% !important;
  }
}