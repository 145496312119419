.registerComp {
    width: 100%;
    height: auto;
    background-color: #f8f8f8;
    display: flex;
    align-items: center;
    justify-content: center;
}

.registerComp1 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 50px;
}

.pageCount {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.pageCount .pageBtn {
    padding: 10px;
    background-color: var(--primaryColor);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    outline: none;
    border: none;
    color: white;
    border-radius: 100%;
}

.pageCount .pageBtn1 {
    padding: 10px;
    background-color: var(--secondaryColor);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    outline: none;
    border: none;
    color: black;
    border-radius: 100%;
}

.pageCount .pageBtn3 {
    padding: 10px;
    background-color: #8B5CF6;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    outline: none;
    border: none;
    color: white;
    border-radius: 100%;
}

.pageCount .pageBtn4 {
    padding: 10px;
    background-color: #8B5CF6;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    outline: none;
    border: none;
    color: white;
    border-radius: 100%;
}

.pageCount p {
    width: 45px;
    height: 0.5px;
    background-color: var(--textColor);
    margin: 0px 7px;
}

.registerInner {
    width: 600px;
    display: flex;
    flex-direction: column;
}

.registerHead,
.registerHead1 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.registerHead h1 {
    font-size: 24px;
    font-weight: 600;
    margin: 15px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.registerHead1 h1 {
    font-size: 24px;
    font-weight: 600;
    margin: 15px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.CompanyDetails {
    padding: 20px;
    border-radius: 7px;
    border: 1px solid var(--borderColor);
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    margin: 10px 0px;

}

.billingDetails1 {
    padding: 20px;
    border-radius: 7px;
    border: 1px solid var(--borderColor);
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    margin: 10px 0px;

}

.billingDetails1 h2 {
    color: var(--textColor);
    font-size: 13px;
    font-weight: 300;
}

.CompanyDetails h2 {
    color: var(--textColor);
    font-size: 14px;
    font-weight: 300;
}

.companyDetails1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    padding: 10px 0px 20px 0px;
}

.companyDetails2 {
    display: flex;
    flex-direction: column;
}

.companyDetails2 h3 {
    font-size: 13px;
    font-weight: 600;
    padding: 8px 0px;
}

.companyDetails2 input {
    width: 100%;
    height: 42px;
    border: 1px solid var(--borderColor);
    border-radius: 7px;
    padding: 15px;
    font-size: 15px;
    outline: none;
}

.companyDetails2 .company {
    width: 100%;
    height: 42px;
    border: 1px solid var(--borderColor);
    border-radius: 7px;
    padding: 15px;
    font-size: 15px;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.company select {
    width: 100%;
    outline: none;
    border: none;
    /* color: var(--borderColor) */
}

.company select::placeholder {
    color: var(--borderColor)
}

.companyDetails2 input::placeholder {
    color: var(--borderColor)
}

.companyUrl h3 {
    font-size: 13px;
    font-weight: 600;
    padding: 8px 0px;
}

.companyUrl input {
    width: 100%;
    height: 42px;
    border: 1px solid var(--borderColor);
    border-radius: 7px;
    padding: 15px;
    font-size: 15px;
    outline: none;
}

.companyUrl input::placeholder {
    color: var(--borderColor);
}

.companyUrl1 h3 {
    font-size: 13px;
    font-weight: 600;
    padding: 8px 0px;
    margin-top: 15px;
}

.companyUrl1 input {
    width: 100%;
    height: 42px;
    border: 1px solid var(--borderColor);
    border-radius: 7px;
    padding: 15px;
    font-size: 15px;
    outline: none;
}

.companyUrl1 select {
    width: 100%;
    /* height: 42px; */
    border: 1px solid var(--borderColor);
    border-radius: 7px;
    padding: 15px;
    font-size: 15px;
    outline: none;
}

.companyUrl1 input::placeholder {
    color: var(--borderColor);
}

.CompanyDetails h2 {
    font-size: 13px;
}

/* YourDeatils */


.yourDetails {
    background-color: var(--white);
    margin: 10px 0px 20px 0px;
    padding: 20px;
    border: 1px solid var(--borderColor);
    border-radius: 7px;
}

.yourDetails h2 {
    color: var(--textColor);
    font-size: 13px;
    font-weight: 300;
}

.detailsGrid {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    grid-gap: 20px;
    padding: 10px 0px 0px 0px;
}

.yourName h3 {
    font-size: 13px;
    font-weight: 600;
    padding: 8px 0px;
}

.yourName input {
    width: 100%;
    height: 42px;
    border: 1px solid var(--borderColor);
    border-radius: 7px;
    padding: 15px;
    font-size: 15px;
    outline: none;
}

.yourName p {
    width: 100%;
    height: 42px;
    border: 1px solid var(--borderColor);
    border-radius: 7px;
    padding: 15px 10px;
    font-size: 15px;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.yourName p select {
    outline: none;
    border: none;
}

.yourName p input {
    outline: none;
    border: none;
    height: 95%;
}

.yourName input::placeholder {
    color: var(--borderColor)
}

/* YourDeatils */



/* Preferences */

.preferences {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 15px 20px;
    border: 1px solid var(--borderColor);
    border-radius: 7px;
    background-color: var(--white) !important;
    margin-bottom: 20px;
}

.preferences h2 {
    color: var(--textColor);
    font-size: 13px;
    font-weight: 300;
}

.hiring {
    display: flex;
    flex-direction: column;
}

.seconddiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.optionaltext {
    color: var(--textColor);
}

.hiring h3 {
    font-size: 13px;
    font-weight: 600;
    padding: 20px 0px 5px 0px;
}

.hiring p {
    width: 100%;
    height: 42px;
    border: 1px solid var(--borderColor);
    border-radius: 7px;
    padding: 15px;
    font-size: 15px;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 10px 0px;
}

.hiring input::placeholder {
    color: var(--borderColor);
}

.hiring h4 {
    margin-left: 6px;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
}

/* Preferences */



/* check */

.RegisterCheck {
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
}

.RegisterCheck h3 {
    width: 500px;
    font-size: 13px;
    font-weight: 600;
    padding: 8px 0px;
}

.RegisterCheck1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
}

.RegisterCheck1 p {
    display: flex;
    align-items: center;
    padding: 15px;
    justify-content: flex-start;
    border-radius: 7px;
    border: 1px solid var(--borderColor);
    margin: 10px 0px;
    height: 43px;
}

.RegisterCheck1 p h4 {
    margin-left: 7px;
    font-size: 14px;
}

.RegisterFeedBack {
    width: 100%;
    height: 120px;
    display: flex;
}

.RegisterFeedBack textarea {
    width: 100%;
    height: 120px;
    border-radius: 7px;
    border: 1px solid var(--borderColor);
    padding: 15px;
    outline: none;
}

/* check */



/* registerBttom*/
.registerBottom {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.registerBottom .nextbtn {
    width: 100%;
    background-color: var(--primaryColor);
    padding: 10px;
    border-radius: 7px;
    outline: none;
    border: none;
    color: var(--white);
}

.nextbtnDisable {
    width: 100%;
    background-color: var(--disableColor);
    padding: 10px;
    border-radius: 7px;
    outline: none;
    border: none;
    color: var(--textColor);
}


.registerBottom .nextbtn1 {
    width: 100%;
    background-color: var(--primaryColor);
    padding: 10px;
    border-radius: 7px;
    outline: none;
    border: none;
    color: var(--white);
}

.registerBottom .skipbtn {
    width: 100%;
    background-color: var(--white);
    padding: 10px;
    border-radius: 7px;
    outline: none;
    border: none;
    color: black;
    margin-top: 20px;
}

.registerBottom h5 {
    color: var(--textColor);
    text-align: center;
    margin: 20px 0px;
    font-weight: normal;
}

.registerBottom h5 span {
    font-weight: bold;
}

.registerBottom h5 span[title] {
    font-weight: bold;
    text-decoration: underline;
}

.termsAndConditions {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px;

}

.termsAndConditions h6 {
    color: var(--textColor);
    font-weight: normal;
    font-size: 12px;
}

.agree {
    width: 100%;
    padding: 10px 18px;
    display: flex;
    border-radius: 7px;
    align-items: center;
    /* justify-content: space-evenly; */
    background-color: #EDE9FE;
}

.terms {
    margin-left: 12px;
}

.spanighLight {
    color: var(--primaryColor);
    margin-left: 5px;
    margin-right: 3px;
}



/* registerBttom*/
.backButtonClientReg {
    width: 90px;
    border-radius: 7px;
    border: 1px solid var(--borderColor);
    padding: 10px;
    color: var(--textColorDark);
    position: absolute;
    left: 2%;
    top: 3%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.logoutReg {
    width: 90px;
    border-radius: 7px;
    border: 1px solid var(--borderColor);
    padding: 10px;
    color: var(--textColorDark);
    position: absolute;
    right: 2%;
    top: 3%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.backButtonClientRegNone {
    display: none;
}

.back {
    margin-right: 10px;
}

@media screen and (max-width: 768px) {
    .registerComp {
        width: auto;
        height: auto;
        background-color: #f1f1f1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
    }

    .registerComp1 {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .pageCount p {
        width: 45px;
        height: 1px;
        background-color: var(--textColor);
        margin: 0px 7px;
    }

    .pageCount {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
    }

    .registerInner {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .CompanyDetails {
        width: auto;
        padding: 20px;
        border-radius: 7px;
        border: 1px solid #E4E4E7;
        display: flex;
        flex-direction: column;
        background-color: white;
        margin: 10px 0px;
    }

    .companyDetails1 {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        display: grid;
        grid-template-columns: repeat(1, 2fr);
        grid-gap: 20px;
        padding: 10px 0px 20px 0px;
    }

    .RegisterCheck h3 {
        width: 100%;
        font-size: 13px;
        font-weight: 200px;
        padding: 8px 0px;
    }

    .registerBottom h5 {
        width: 80%;
        color: var(--textLightColor);
        text-align: center;
        margin: 20px 0px;
        font-weight: normal;
        text-align: center;
        font-size: 14px;
    }

    .registerHead h1 {
        width: 80%;
        text-align: center;
        padding: 10px 0px;
    }

    .registerHead1 h1 {
        width: 80%;
        text-align: center;
        padding: 10px 0px;
    }

    .detailsGrid {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        display: grid;
        grid-template-columns: repeat(1, 4fr);
        grid-gap: 20px;
        padding: 10px 0px 0px 0px;
    }

    .logoutReg {
        width: 14%;
        padding: 5px 5px;
        font-size: 12px;
        left: 5%;
        top: 2.5%;
    }
}