.newClientFlow1 {
    padding-top: 50px;
    width: 650px;
    height: 100%;
}

.newClientFlow h1 {
    margin-top: 50px;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
}

.skilltext {
    font-size: 10px !important;
}


.newClientFlow1 h1 {
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    margin-top: 20px;
}

.newClientFlow1 h2 {
    text-align: center;
    font-size: 20px;
    margin-bottom: 10px;
    /* font-weight: 500; */
}

.newClientFlowBody {
    margin-top: 20px;
    padding: 24px;
    background-color: var(--white);
    border-radius: 10px;
    border: 1px solid var(--borderColor);
}

.newClientFlowContent {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.newClientFlowContent1 {
    width: 48%;
    margin-bottom: 20px;
}

.newClientFlow_Content {
    width: 100%;
    margin-bottom: 20px;
}

.newClientFlow_Content h2,
.newClientFlowContentSelect h2 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
    text-align: start;
}

.newClientFlowContent1 h2,
.newClientFlowContentSelect h2 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
    text-align: start;
}

.newClientFlowContentSelectnew h3 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
    text-align: start;
}
.labeltext {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
    text-align: start;
}


.newClientFlowContent1Select {
    width: 100%;
    border-radius: 10px;
    padding: 8px;
    border: 1px solid var(--borderColor);
}

.newClientFlowContent1 input {
    width: 100%;
    border-radius: 8px;
    padding: 8px;
    border: 1px solid var(--borderColor);
}

.newClientFlowContentSelect {
    width: 100%;
    margin-bottom: 20px;
}

.newClientFlowContentSelectnew {
    width: 100%;
    margin-bottom: 20px;
}

.newClientFlowContentSelect input {
    width: 55%;
    padding: 8px;
    border: 1px solid var(--borderColor);
    border-left: none;
    border-right: none;
    padding-left: 20px;
}

.newClientFlowContentSelectnew input {
    width: 100%;
    padding: 8px;
    border: 1px solid var(--borderColor);
    padding-left: 20px;
    height: 45px;
    border-radius: 0px 8px 8px 0px;
}

.newClientFlowContentSelectnew1 input {
    width: 100% !important;
    padding: 8px;
    border: 1px solid var(--borderColor);
    padding-left: 20px;
    height: 45px;
    border-radius: 8px 8px 8px 8px;
}

.newClientFlowContentSelectnew select {
    width: 30%;
    padding: 11px;
    border: 1px solid var(--borderColor);
    font-size: 14px;
    font-weight: 600;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    height: 45px;
    color: #808080;
    font-weight: 400;
}

.successerror {
    color: green !important;
    margin-top: 10px;
    font-weight: 500;
}

.verifybtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.newClientFlowContentSelect select {
    width: 30%;
    padding: 11px;
    border: 1px solid var(--borderColor);
    font-size: 14px;
    font-weight: 600;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

}

.newClientFlowContentSelectDisable {
    width: 15%;
    padding: 9.5px;
    border: 1px solid var(--borderColor);
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    color: var(--primaryColor);
    display: flex;
    align-items: center;
    justify-content: center;
}

.newClientFlowContentSelectEnable {
    width: 22%;
    padding: 9.5px;
    border: 1px solid var(--borderColor);
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    color: var(--verifyColor);
}

.newClientFlowContentSelectEnable h4 {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.newClientFlowContentInput {
    display: flex;
    align-items: flex-start;
}

.newClientFlowContent1 select {
    border-radius: 8px;
    padding: 8px;
    border: 1px solid var(--borderColor);
}

.newClientFlowSupport {
    width: 100%;
    text-align: center;
    padding: 30px 0px;
}

.newClientFlowSupportnew {
    width: 100%;
    text-align: center;
    padding: 10px 0px;
}

.newClientFlowSupportnew h5 {
    font-size: 14px;
    /* margin-right: 50px; */
    font-weight: 500;
    color: var(--textColor);
}

.newClientFlowSupportnew a {
    color: var(--primaryColor);
    margin-left: 5px;
}



.newClientFlowSupport h5 {
    font-size: 14px;
    /* margin-right: 50px; */
    font-weight: 500;
    color: var(--textColor);
}

.newClientFlowSupport a {
    color: var(--textColorDark);
    margin-left: 5px;
}

.newClientFlowTermsAndConditions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 30px;
    /* padding-top: 50px; */
    padding-bottom: 50px;
}


.newClientFlowTermsAndConditions h6 {
    font-size: 14px;
    margin-right: 50px;
    font-weight: 500;
    color: var(--textColor);
    cursor: pointer;
}

.buttonWidth100 {
    width: 100% !important;
}

.buttonWidth85 {
    width: 85% !important;
    border-left: 1px solid var(--borderColor) !important;
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
}

/* Preferece */
.newClientFlowContentPreferece {}

.newClientFlowContentPreferece1 {
    width: 100%;
    display: flex;
    border: 1px solid var(--borderColor);
    border-radius: 8px;
    padding: 10px;
    margin-top: 15px;
}

.newClientFlowContentPreferece1 a {
    color: var(--primaryColor);
    /* text-decoration: underline;
        text-underline-offset: 5px; */
    font-weight: 500;
}

.borderBottomRadius {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.newClientFlowContentPreferece1 h4 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
}

.newClientFlowContentPreferece1 input {
    margin-right: 15px;
}

.contactMode {
    padding: 24px;
    border: 1px solid var(--borderColor);
    border-top: none;
    border-radius: 8px;
    border-top-left-radius: 0%;
    border-top-right-radius: 0%;
}

.contactMode h2 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
}

.contactModeFlex1 {
    display: flex;
    margin-bottom: 20px;
    width: 100%;
    gap: 1rem;
}

.contactModeFlex {
    display: flex;
    margin-bottom: 10px;
}

.contactModeFlexInput {
    width: 100%;
    border: 1px solid var(--borderColor);
    border-radius: 8px;
    padding: 10px;
}

.contactModeFlexAgree {
    display: flex;
    align-items: flex-start;
    padding: 20px;
    background-color: #E9ECFE;
    border-radius: 8px;
}

.contactModeFlexAgree input {
    margin-right: 10px;
    margin-top: 10px;
    border: none;
}

.contactModeFlexAgree h4 {
    font-size: 16px;
    font-weight: 600;
}

/* overlay */
.newClientFlowVerification {
    width: 30%;
    padding: 30px;
    background-color: var(--white);
    border-radius: 12px;
    text-align: center;
}

.newClientFlowVerification h1 {
    font-size: 24px;
    font-weight: 600;
}

.newClientFlowVerification p {
    font-size: 14px;
    color: var(--textColor);
}

.newClientFlowVerification a {
    color: var(--textColorDark);
    margin-left: 5px;
}

.newClientFlowVerification h6 {
    font-size: 14px;
    color: var(--textColor);

}

.newClientFlowVerification span {
    color: var(--primaryColor);
    margin-left: 5px;
    font-weight: 500;
}

.close {
    position: absolute;
    padding: 10px;
    border: 1px solid var(--borderColor);
    border-radius: 8px;
    top: 10px;
    right: 10px;
}

.mobVerifyButton {
    padding: 8px 50px;
    background-color: var(--primaryColor);
    margin-top: 20px;
    margin-bottom: 20px;
    color: var(--white);
    border-radius: 8px;

}

.mobVerifyButtondisable {
    padding: 8px 50px;
    background-color: var(--borderColor);
    margin-top: 20px;
    margin-bottom: 20px;
    color: var(--white);
    border-radius: 8px;

}

.ShareReqiurementSection {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
    background-color: var(--white);
    padding: 20px;
    border-radius: 12px;
}

.ShareReqiurementSection button {
    background-color: var(--primaryColor);
    padding: 8px 20px;
    color: var(--white);
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
}

.skipButton {
    text-align: center;
    margin-top: 30px;
    color: var(--primaryColor);
    text-decoration: underline;
    text-underline-offset: 4px;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
}

.ShareReqiurementSectionContent {
    width: 55%;
}

.ShareReqiurementSectionContent p {
    font-size: 14px;
    color: var(--textColor);
    margin-top: 5px;
    text-align: start;
}


.newClientFlowContent2 {
    display: flex;
}

.newClientFlowContent2 input {
    width: 80%;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: none;
}

.newClientFlowContent2 select {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.QuestionsSubmitButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
}

.skipEnable {
    width: 100%;
    padding: 8px;
    border: 1px solid var(--primaryColor);
    color: var(--primaryColor);
    font-weight: 600;
    border-radius: 8px;
}

.skipDisable {
    width: 100%;
    padding: 8px;
    border: 1px solid var(--primaryColor);
    color: var(--textColor);
    background-color: #F3F5F6;
    font-weight: 600;
    border-radius: 8px;
}

.acceptbtn {
    padding: 5px 15px;
    color: var(--white);
    border: none;
    border-radius: 8px;
    background-color: var(--verifyColor);
    font-weight: 500;
}

.viewbtn {
    padding: 5px 15px;
    color: var(--white);
    border: none;
    border-radius: 8px;
    background-color: var(--primaryColor);
    font-weight: 500;
}


.accept_btn {
    padding: 5px 15px;
    color: var(--white);
    border: none;
    border-radius: 8px;
    background-color: yellowgreen;
    font-weight: 500;
}

.Send_btn {
    padding: 5px 15px;
    color: var(--white);
    border: none;
    border-radius: 8px;
    background-color: var(--verifyColor);
    font-weight: 500;
}
.Save_btn {
    padding: 5px 15px;
    color: var(--white);
    border: none;
    border-radius: 8px;
    background-color: var(--primaryColor);
    font-weight: 500;
}

.Send_btn_disable {
    padding: 5px 15px;
    color: var(--white);
    border: none;
    border-radius: 8px;
    background-color: var(--verifyColor);
    font-weight: 500;
    opacity: 0.2;
    cursor: not-allowed;
}

.exportntn {
    padding: 5px 15px;
    color: var(--white);
    border: none;
    border-radius: 8px;
    background-color: var(--primaryColor);
    font-weight: 500;
    float: right;
    margin-bottom: 10px;
}

.rejectbtn {
    padding: 5px 15px;
    color: var(--white);
    border: none;
    border-radius: 8px;
    background-color: var(--rejectColor);
    font-weight: 500;
}

.skipDisable:hover {
    border: 1px solid var(--primaryColor);
    color: var(--primaryColor);
}

.SubmitDisable {
    width: 100%;
    padding: 8px;
    border: 1px solid var(--transparent);
    color: var(--textColor);
    background-color: #F3F5F6;
    font-weight: 600;
    border-radius: 8px;
    margin-left: 15px;
}


.SubmitEnable {
    width: 100%;
    padding: 8px;
    color: var(--white);
    background-color: var(--primaryColor);
    font-weight: 600;
    border-radius: 8px;
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.newClientSuccessResponse {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 600px) {
    .newClientFlowContent {
        flex-direction: column;
    }

    .newClientFlow1 {
        width: 100%;
        padding-top: 100px;
        padding: 15px;
    }

    .newClientFlowVerification {
        width: 90%;
        padding: 30px;
        background-color: var(--white);
        border-radius: 12px;
        text-align: center;
    }

    .newClientFlowTermsAndConditions {
        margin-left: 0px;
        padding-top: 10px;
    }

    .newClientFlowContent1 {
        width: 100%;
    }

    .newClientFlowContentSelectDisable {
        width: 20%;
    }

    .newClientFlowContentSelect select {
        width: 26%;
    }

    .newClientFlowBody {
        padding: 15px;
    }

    .newClientFlowContentPreferece1 h4 {
        margin-bottom: 0%;
        font-size: 14px;
    }

    .contactMode {
        padding: 15px;
    }

    .contactModeFlex {
        flex-direction: column;
    }

    .contactModeFlex1 {
        flex-direction: column;
    }

    .newClientFlowTermsAndConditions h6 {
        margin-right: 20px;
        margin-left: 20px;
    }

    .Agree span {
        margin: 0px 5px;
    }

    .Agree input {
        margin-right: 8px;
        margin-left: 5px;
    }

    .newClientFlow1 h2 {
        font-size: 18px;
        margin-top: 20px;
    }

    .newClientFlow1 h1 {
        font-size: 18px;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
}