.mobileCandidateProfile {
    margin-top: 10px;
    margin-bottom: 15px;
}

.mobCandidateProfileBack {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
    margin-bottom: 20px;
}

.mobCandidateProfileBack h1 {
    font-size: 14px;
    font-weight: 600;
    margin-left: 15px;
}

.mobileCandidateProfileDetails {
    padding: 15px;
    border: 1px solid var(--borderColor);
    border-radius: 8px;
    background-color: var(--dropColor);
}

.mobileCandidateProfileDetailsFlex {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 20px;

}

.mobileCandidateProfileDetailsLeft {
    width: 80px;
    height: 80px;
}

.mobileCandidateProfileDetailsLeft img {
    width: 100%;
    height: 100%;
}

.mobileCandidateProfileDetailsButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
}

.candidateGetInTouch {
    width: 43%;
    background-color: var(--primaryColor);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 11px 15px;
    border-radius: 8px;
    font-size: 13px;
    font-weight: 600;
    color: var(--white);
}



.candidateReserve {
    width: 55%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-radius: 8px;
    font-size: 13px;
    font-weight: 600;
    opacity: 0.7;
    color: var(--textColorDark);
    border: 2px solid rgb(130, 130, 130);
}

.mobileCandidateProfileDetailsRight {
    position: relative;
}

.mobileCandidateProfileDetailsRight h1 {
    font-size: 16px;
    font-weight: 600;
}

.mobileCandidateProfileDetailsRight h2 {
    font-size: 14px;
    font-weight: 600;
}

.mobileCandidateProfileDetailsRight span {
    font-size: 14px;
    font-weight: 400;
    margin-right: 5px;
    color: var(--textColor);
}

.mobileCandidateProfileDetailsRightImg {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    font-size: 16px;
    color: #71717A;
    top: 0%;
    right: 0%;
}

/* demographic */
.demographic {
    margin-top: 20px;
}

.demographicHead {
    width: 100%;
    padding: 8px;
    background-color: var(--tabHeadColor)
}

.demographicHead h1 {
    font-size: 14px;
    font-weight: 600;
    color: var(--white);
}

.demographicTab {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 5px;
    border-bottom: 1px solid var(--borderColor);
}

.tabActive {
    /* width: 150px; */
    padding: 10px 15px;
    font-size: 14px;
    font-weight: 500;
    color: var(--primaryColor);
    border-bottom: 2px solid var(--primaryColor);
}

.tabInAvtive {
    /* width: 150px; */
    padding: 10px 15px;
    font-size: 14px;
    font-weight: 500;
    opacity: 0.6;
    border-bottom: 1px solid transparent;

}

.demographicBody {
    padding-bottom: 15px;
}

.demographicBodyContent {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: start;
}

.demographicBodyContent1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: start;
}

.demographicBodyContent h4 {
    width: 55%;
    font-size: 12px;
    font-weight: 600;
    padding: 10px;
}

.demographicBodyContent h5 {
    width: 45%;
    font-size: 12px;
    font-weight: 400;
    padding: 10px;
    color: var(--textColor);
}

.demographicBodyContent1 span {
    font-size: 14px;
    font-weight: 600;
    margin-top: 15px;
}

.demographicBodyContent1 h6 {
    font-size: 14px;
    font-weight: 600;
    margin-top: 15px;
}