.wholeTech {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0px;
}

.subTech {
  width: 88%;
}

.subTech h1 {
  font-family: var(--primaryFont);
  font-size: 48px;
  font-weight: 500;
  line-height: 58.09px;
  letter-spacing: 0.005em;
  text-align: left;
  color: #18181b;
}

.subTech p {
  font-family: var(--primaryFont);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.005em;
  text-align: left;
  color: #71717a;
}

.assessmentGrid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 30px;
}

.assessmentCard {
  width: 100%;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0px 2.83px 2.83px 0px #00000040;
  border-radius: 12px;
}

.assessmentHead {
  display: flex;
  align-items: center;
}

.assessmentHead h2 {
  font-family: var(--primaryFont);
  font-size: 14.17px;
  font-weight: 500;
  line-height: 17.14px;
  text-align: left;
  color: black;
}

.assessmentCard p {
  font-family: var(--primaryFont);
  font-size: 8.5px;
  font-weight: 400;
  line-height: 10.29px;
  text-align: left;
  color: var(--primaryColor);
  margin-left: 20px;
  padding: 5px 10px;
  border: 1px solid #1d37e7;
  border-radius: 10px;
  text-align: center;
}

.assessmentButton {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.assessmentButton button {
  padding: 10px 20px;
  font-family: var(--primaryFont);
  font-size: 11.33px;
  font-weight: 400;
  line-height: 13.72px;
  text-align: left;
  color: white;
  background-color: var(--primaryColor);
  border-radius: 8px;
  border: none;
  outline: none;
}

.assessmentButton img {
  width: 80px;
}