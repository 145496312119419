span.MuiSlider-track.css-1gv0vcd-MuiSlider-track {
    color: var(--primaryColor);
    height: 5px;
}

span.MuiSlider-root.MuiSlider-colorPrimary.MuiSlider-sizeMedium.text-black.css-187mznn-MuiSlider-root {
    color: var(--disableColor);
    height: 5px;
}

span.MuiSlider-thumb.MuiSlider-thumbSizeMedium.MuiSlider-thumbColorPrimary.MuiSlider-thumb.MuiSlider-thumbSizeMedium.MuiSlider-thumbColorPrimary.css-jx1sa5-MuiSlider-thumb {
    color: var(--white);
}

.MuiSlider-valueLabelLabel {
    color: var(--textColorDark);
    background-color: var(--white);
    text-align: center;
    font-weight: 600;
    padding: 6px 10px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    font-size: 16px;
}

.MuiSlider-valueLabel {
    background-color: var(--white) !important;
}

.css-v3ate2 {
    width: 100% !important;
}

@media only screen and (max-width: 600px) {
    .css-v3ate2 {
        width: 100%;
    }
}