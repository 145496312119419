.newLogin {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../../assests/Onboarding-Background.webp");
  background-size: 100% 100%;
  overflow: hidden;
}

.subLogin {
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerSide {
  width: 50%;
}

.headerSide h3 {
  width: 60%;
  font-family: var(--primaryFont);
  font-weight: 700;
  font-size: 80px;
  line-height: 96.82px;
  color: var(--white);
  margin-top: 15px;
}

.headerSide h6 {
  font-family: var(--primaryFont);
  font-weight: 600;
  font-size: 30px;
  line-height: 36.31px;
  color: var(--white);
  margin-top: 15px;
}

.headerSide p {
  font-family: var(--primaryFont);
  font-weight: 400;
  font-size: 24px;
  line-height: 29.05px;
  color: var(--white);
  margin-top: 15px;
}

.loginSide {
  width: 38%;
  padding: 20px 0px;
  background-color: var(--white);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loginBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}

.fLogin {
  font-family: var(--primaryFont);
  font-weight: 600;
  font-size: 24px;
  line-height: 29.05px;
  text-align: center;
  color: var(--textColorDark);
  margin-top: 15px;
}

.fAcc {
  font-family: var(--primaryFont);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: var(--textColor) !important;
  margin-top: 10px;
}

.loginSide span {
  font-family: var(--primaryFont);
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: var(--oldPrimaryColor);
  cursor: pointer;
}

.formContainer {
  width: 85%;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.label {
  font-family: var(--primaryFont);
  font-weight: 600;
  font-size: 14px;
  line-height: 16.94px;
  color: var(--lableColor);
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.input {
  width: 100%;
  border-radius: 7px;
  outline: none;
  background-color: var(--white);
}

.border {
  border: 1px solid var(--borderColor2);
  box-shadow: 0px 1px 2px 0px #0000000d;
  padding: 8px;
  margin-top: 10px;
}

.formContainer::placeholder {
  font-family: var(--primaryFont);
  font-weight: 500;
  font-size: 15px;
  line-height: 18.15px;
  color: var(--placeholderColor);
}

.passwordDiv {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--borderColor2);
  border-radius: 7px;
  box-shadow: 0px 1px 2px 0px #0000000d;
  background-color: var(--white);
  margin-top: 10px;
}

.button {
  background-color: var(--buttonBg);
  border-radius: 7px;
  color: var(--buttonClr);
  font-family: var(--primaryFont);
  font-weight: 600;
  font-size: 15px;
  line-height: 18.15px;
  text-align: center;
}

.forOr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-top: 15px;
}

.forOr hr {
  width: 100%;
  height: 2px;
  background-color: var(--borderColor2);
}

.forOr p {
  font-family: var(--primaryFont);
  font-weight: 500;
  font-size: 16px;
  line-height: 19.36px;
  color: var(--mildTextColor);
}

.google {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  background-color: var(--white);
  border-radius: 7px;
  margin-top: 15px;
  cursor: pointer;
}

.google p {
  font-family: var(--primaryFont);
  font-weight: 400;
  font-size: 16px;
  line-height: 19.36px;
  color: var(--mildTextColor2);
}

.termPrivacy p {
  font-family: var(--primaryFont);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: var(--textColor);
  margin-top: 15px;
}

.termPrivacy span {
  font-family: var(--primaryFont);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5%;
  text-align: center;
  text-decoration: underline;
  text-decoration-style: solid;
  color: var(--textColorDark);
  cursor: pointer;
}

.padding {
  padding: 8px !important;
}

@media only screen and (max-width: 600px) {
  .newLogin {
    background-image: url("../../../assests/MobBg.webp") !important;
    background-size: 100% 100%;
    background-position: center;
    /* background-attachment: fixed; */
    object-fit: cover;
    /* overflow: visible !important; */
    height: auto !important;
    padding-top: 30px;
  }

  .subLogin {
    width: 100%;
    padding: 10px;
    flex-direction: column;
  }

  .headerSide {
    width: 100%;
  }

  .headerSide h3 {
    width: 40%;
    font-size: 26px;
    line-height: 31.47px;
  }

  .headerSide span {
    font-family: var(--primaryFont);
    font-weight: 700;
    font-size: 30px;
    line-height: 36.31px;
  }

  .headerSide h6 {
    font-size: 20px;
    width: 100%;
    line-height: 24px;
  }

  .headerSide p {
    font-size: 16px;
    line-height: 19px;
  }

  .loginSide {
    width: 100%;
    margin-top: 25px;
  }

  .formContainer {
    width: 95%;
  }

  .headerSide img {
    width: 50px;
    height: 50px;
  }

  .fLogin {
    font-size: 22px;
  }
}