.dashboard {
    width: 100%;
    /* height: 100vh; */
    display: flex;
    /* align-items: center; */
    justify-content: center;
}

.sideNavComp {
    width: 18%;
    height: 100%;
    background-color: var(--white);
    position: sticky;
    top: 0;
    left: 0;
    overflow-y: scroll;
}

.sideNavComp::-webkit-scrollbar {
    display: none;
}

.main {
    width: 82%;
    /* height: 100vh; */
    overflow-y: scroll;
    /* padding: 0px 120px; */
    background-color: #fafafa !important;
    /* margin-left: 18%; */
}

.MobScreen {
    display: none;
}

.main::-webkit-scrollbar {
    display: none;
}

.sidebarOverlay {
    width: 80%;
    height: 100%;
    display: flex;
    z-index: 10 !important;
    flex-direction: column;
    border-radius: 12px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-38%, -50%);
}

.fadeIn {
    animation: fadeInAnimation 0.5s ease-in forwards;
    opacity: 1;
}

@keyframes fadeInAnimation {
    from {
        transform: translate(60%, -50%);
    }

    to {
        transform: translate(-38%, -50%);
    }
}

.fadeOut {
    animation: fadeInAnimation1 0.5s ease-in forwards;
    opacity: 1;
}

@keyframes fadeInAnimation1 {
    from {
        transform: translate(-38%, -50%);
    }

    to {

        transform: translate(62%, -50%);
    }
}

@media only screen and (max-width: 600px) {
    .MobScreen {
        display: block;
    }

    .dashboard {
        display: none;
    }
}