.interviewHead {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
}

.interviewCard {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

}

.interviewimg {
    width: 30px;
    height: 30px;
    object-fit: cover;
}

@media only screen and (max-width: 600px) {
    .interviewCard {
        grid-template-columns: repeat(1, 1fr);
        padding-left: 22px;
    }
}