.dashBoardMain {
    /* width: 82%; */
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    background-color: #f8f8f8;
}

.dashBoardMain::-webkit-scrollbar {
    display: none;
}