/* head */
.sideNavHead {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

}

.sideNavHead h1 {
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: 500;
    margin-left: 0.5rem;
}

/* SideNav */
.sideNav {
    width: 100%;
    height: 100vh;
    background-color: var(--white);
}

.sideNavInner {
    width: 100%;
    /* height: 94%; */
    background-color: var(--white);
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

}

.sideNavTop {
    width: 100%;
}

.sideNavBottom {
    width: 100%;
}



.dashProfile {
    /* display: flex; */
    padding: 15px;
    border-radius: 8px;
    /* align-items: center;
    justify-content: space-between; */
    background-color: #f8f8f8;
    margin-bottom: 20px;
}

.profileName {
    width: 70%;
}

.profileName h2 {
    font-weight: 600;
    font-size: 18px;
}

.profileName p {
    font-size: 15px;
    color: var(--textColor);
}

.profilePic img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.cash {
    display: flex;
    border-radius: 8px;
    align-items: center;
    justify-content: flex-start;
    padding: 12px;
    border: 1px solid var(--borderColor);
}

.cashLeft {
    background-color: #f8f8f8;
    padding: 10px;
    border-radius: 8px;
}

.cashRight {
    margin-left: 15px;
}

.cashRight p {
    font-size: 12px;
    color: var(--textColor);
}

.cashRight h5 {
    font-weight: 600;
}

.cashRight span {
    margin-right: 5px;

}


.logout {
    display: flex;
    padding: 15px 25px;
    border-top: 1px solid var(--borderColor);
    padding-bottom: 0px;
    cursor: pointer;
}

.logout img {
    margin-right: 10px;

}

.logout h6 {
    font-size: 14px;

}

.navMenu {
    width: 100%;
    height: 100%;
    /* padding: 15px; */
}

.menu1 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 10px;
}

.menuName {
    font-size: 15px;
    font-weight: 500;
}

.menuIcon {
    font-size: 18px;
    object-fit: cover;
    margin-right: 10px;
}

.menu1:hover {
    background-color: var(--loadingColor);
}

.menu1:hover .menuName {
    color: var(--primaryColor);
}

.menu1:hover .menuIcon {
    color: var(--primaryColor);
}


.menu1Active {
    background-color: var(--loadingColor);
    color: var(--primaryColor);
    color: var(--primaryColor);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 10px;
}

.number {
    height: 18px;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 500;
    /* padding: 1px 5px; */
    background-color: #e4deff;
    border-radius: 50%;
    margin-left: 6px;
}

/* overlay */
.logoutPopUp {
    width: 30%;
    height: auto;
    background-color: white;
    display: flex;
    z-index: 10 !important;
    flex-direction: column;
    /* padding: 10px; */
    border-radius: 12px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
}

.logoutPopUpHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.logoutPopUpHead h1 {
    font-size: 16px;
    font-weight: 600;
}

.logoutPopUpHead img {
    cursor: pointer;
}

.logoutPopUp h2 {
    font-size: 14px;
    font-weight: 400;
    color: var(--textColor);
}

.logoutInaciveButton {
    padding: 10px;
    font-size: 14px;
    background-color: var(--white);
    border-radius: 8px;
    border: 1px solid var(--textColor);
    color: var(--textColor);
    font-weight: 500;
    margin-top: 10px;
}

.logoutActiveButton {
    padding: 10px;
    font-size: 14px;
    background-color: var(--primaryColor);
    border-radius: 8px;
    border: none;
    color: var(--white);
    margin-top: 15px;
}

.IconClose {
    position: absolute;
    top: 4.5%;
    right: 10%;
    display: none;
}

/* progress bar */

.sidebarProgressBar {
    padding: 15px;
    border: 1px solid var(--borderColor);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sidebarProgressBarImg {
    height: 60px;
    width: 60px;
}

.sidebarProgressBar h1 {
    font-size: 14px;
    font-weight: 600;
    color: var(--textColorDark);
    margin-top: 10px;
    margin-bottom: 10px;
    opacity: 0.7;
}

.sidebarProgressBar p {
    font-size: 14px;
    font-weight: 500;
    color: var(--textColor);
    text-align: center;
}

.sidebarProgressBar h2 {
    cursor: pointer;
    font-size: 15px;
    /* margin-top: 15px;
    margin-bottom: 10px; */
    font-weight: 600;
    color: var(--primaryColor);
}

.sidebarProgressBar h4 {
    cursor: pointer;
    font-size: 15px;
    /* margin-top: 15px;
    margin-bottom: 10px; */
    font-weight: 600;
    color: #A16207;
}

.sidebarProgressBar h5 {
    cursor: pointer;
    font-size: 15px;
    /* margin-top: 15px;
    margin-bottom: 10px; */
    font-weight: 600;
    color: #15803D;
}


.sidebarProgressBar h3 {
    cursor: pointer;
    font-size: 15px;
    /* margin-top: 15px;
    margin-bottom: 10px; */
    font-weight: 600;
    color: var(--rejectColor);
}

@media only screen and (max-width: 600px) {
    .sideNavInner {
        height: 92%;
    }

    .IconClose {
        display: block;
    }
}