.clientDiscoverOuter {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.clientDiscoverInner {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.percentage_tag {
    background-color: #1d37e7;
    border-radius: 20px;
    padding: 0px 8px;
    font-size: 12px;
    color: white;
    font-weight: 600;
    border: 1px solid white;
    position: absolute;
    top: -2%;
    right: 8%;
}
.staticbtn{
    background-color: #1d37e7;
    padding: 8px 20px;
    font-size: 12px;
    color: white;
    font-weight: 600;
    border: 1px solid white;
    border-radius: 10px;
}
.dynamicbtn{
    background-color: transparent;
    padding: 8px 20px;
    font-size: 12px;
    color: black;
    font-weight: 600;
    border: 1px solid gray;
    border-radius: 10px;
}

.clientDiscoverTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.clientDiscoverTop h1[title] {
    color: var(--primaryColor);
    font-size: 16px;
    font-weight: 500;
}

.clientDiscoverInner h1 {
    font-size: 16px;
    font-weight: 600;
}

.clientDiscoverInner .clientDiscover {
    display: flex;
    flex-direction: column;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin: 10px 0px;
}

.clientDiscover1 {
    display: flex;
    flex-direction: column;
    padding: 15px;
    border-radius: 10px;
    background-color: var(--white);
    border: 1px solid var(--borderColor);
    cursor: pointer;
    position: relative;
    margin-bottom: 10px;
}

.candidateDiscoverProfile {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.candidateDiscoverProfile .candidateDiscoverProfile1 {
    width: 55%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
}

.candidateDiscoverImage {
    border-radius: 100%;
}

.candidateDiscoverImage img {
    border-radius: 100%;
    height: 50px;
    width: 50px;
    object-fit: cover;
}

.candidateDiscoverName {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
}

.candidateDiscoverName h3 {
    font-size: 18px;
    font-weight: 600;
}

.candidateDiscoverName h5 {
    font-size: 12px;
    font-weight: 400;
    margin-top: 4px;
}

.candidateDiscoverHours {
    height: 100%;
}

.candidateDiscoverHours h2 {
    font-size: 14px;
    font-weight: 400;
    color: var(--emailHighLighter);
}

.candidateDiscoverSkills {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 10px 0px;
}

.candidateDiscoverSkillsH4 {
    margin: 5px 10px;
    padding: 2px 7px;
    margin-left: 0px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    background-color: #F4F4F5;
    background-color: var(--disableColor);
    font-size: 14px;
    font-weight: 400;
    color: var(--textColorDark);
}

.candidateDiscoverSkillsBgcolor {
    margin: 5px 10px;
    padding: 2px 7px;
    margin-left: 0px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: var(--primaryColor);
    /* background-color: var(--loadingColor); */
    background-color: #E9E9FF;
}

.candidateDiscoverSkills svg {
    object-fit: cover;
    height: 20px;
    width: 20px;
    margin-right: 5px;
}

.candidateDiscoverExperience {
    width: auto;
    display: flex;
    flex-direction: column;
    display: grid;
    grid-template-columns: repeat(1, 2fr);
    grid-row-gap: 10px;
    grid-column-gap: 20px;
}

.candidateDiscoverExp {
    width: 100%;
}

.candidateDiscoverExp p {
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: var(--emailHighLighter);
}

.candidateDiscoverExp p img {
    margin-right: 6px;
}

@media only screen and (max-width: 600px) {
    .clientDiscoverInner .clientDiscover {
        grid-template-columns: repeat(1, 1fr);
    }
}