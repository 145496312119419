.errortag {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
}

.errormag {
    font-size: 12px;
    font-weight: 600;
    line-height: 30px;
    color: red;
    margin-top: 5px;
}

.error-tag {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    color: red;
}

.linktag {
    color: var(--primaryColor);
}