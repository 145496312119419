.newClientSignup {
  width: 100%;
  height: 118vh;
  /* height: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageSide {
  width: 50%;
  height: 100%;
  background-image: url("../../../assests/BlueClr.webp");
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 50px;
}

.videoborder {
  border: 10px solid white;
}

.imagesideptag {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 30px;
  line-height: 56px;
  text-align: center;
  color: white;
  margin-bottom: 30px;
}

.videoresume {
  width: 53%;
  height: 35%;
  border-radius: 15px;
  border: 20px solid var(--white);
  background-color: var(--white);
  position: relative;
}

.playButton {
  position: absolute;
  color: white;
  padding: 10px;
  border-radius: 10px;
  backdrop-filter: blur(31.245357513427734px);
  border: 0.98px solid #FFFFFF14;
  top: 40%;
  left: 45%;
  font-size: 30px;
}

.videoresume img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.textvideoresume {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  background-color: var(--white);
  margin-top: 20px;
  border-radius: 30px;
  gap: 10px;
}

.textvideoresume p {
  font-family: var(--primaryFont);
  font-weight: 500;
  font-size: 17.2px;
  line-height: 45.33px;
  color: var(--mildTextColor2);
}

.videoptag {
  font-family: var(--primaryFont);
  font-weight: 600;
  font-size: 30px;
  line-height: 36.31px;
  text-align: center;
  color: var(--white);
  margin-top: 30px;
}

.contenttag {
  width: 90%;
  font-family: var(--primaryFont);
  font-weight: 400;
  font-size: 20px;
  /* line-height: 29px; */
  text-align: left;
  color: var(--white);
  list-style: disc !important;
}

.checkCirlce {
  font-size: 25px;
  margin-right: 15px;

}

.formSide {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 70px;
}

.formSide h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  margin-top: 30px;
}

.descriptiondiv {
  width: 66%;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-top: 10px;
  color: var(--mildTextColor2);
}

.formDiv {
  width: 78%;
  margin-top: 40px;
}

.firstandlast {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.firstandlast p {
  font-family: var(--primaryFont);
  font-weight: 600;
  font-size: 14px;
  line-height: 16.94px;
}

.firstName {
  width: 48%;
}

.lastName {
  width: 48%;
}

.cmpEmail {
  width: 100%;
  margin-top: 20px;
}

.cmpEmail p {
  font-family: var(--primaryFont);
  font-weight: 600;
  font-size: 14px;
  line-height: 16.94px;
}

.smallInfo {
  font-family: var(--primaryFont);
  font-weight: 400;
  font-size: 10px !important;
  line-height: 12.1px;
  color: #2F2F2A;
}

.terms {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  /* margin-top: 10px; */
}

.gap {
  gap: 0px;
  margin-top: 10px;
}

.buttonDiv button {
  width: 100%;
  font-family: var(--primaryFont);
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: var(--white);
  background-color: var(--primaryColor);
  border: none;
  border-radius: 30px;
  padding: 10px 20px;
  cursor: pointer;
}

@media(max-width:600px) {
  .newClientSignup {
    flex-direction: column;
    height: auto;
  }

  .imageSide {
    width: 100%;
    padding: 20px 0px;
  }

  .formSide {
    width: 100%;
  }

  .imagesideptag {
    margin-bottom: 15px;
    font-size: 25px;
  }

  .videoptag {
    font-size: 20px;
    margin-top: 15px;
  }

  .videoresume {
    width: 63%;
    border: 10px solid var(--white);
  }

  .videoresume img {
    width: 100%;
    height: 150px;
  }

  .textvideoresume p {
    font-size: 14px;
    line-height: 35px;
  }

  .contenttag {
    width: 84%;
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
  }

  .formSide h6 {
    font-size: 25px;
    margin-top: 10px;
  }

  .descriptiondiv {
    width: 88%;
    margin-top: 0px;
  }

  .formDiv {
    width: 87%;
  }

}