.adminTeamMember {
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    border: 1px solid #E4E4E7;
    border-radius: 10px;
    margin-bottom: 50px;
}

.teamMemberInputs {
    width: 100%;
    height: 100%;
    /* background-color: green; */
    display: flex;
    flex-direction: column;
    margin: 10px 0px;
}

.adminTeamMember h3 {
    font-size: 18px;
    font-weight: 600;
}

.teamMemberInputs h4 {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 600;
}

.teamMemberInputs input {
    padding: 10px;
    border-radius: 7px;
    border: 1px solid #E4E4E7;
}

.teamMemberInputs input::placeholder {
    color: #D4D4D8;
}

.teamMemberLevel {
    width: 40%;
    height: 100%;
    /* background-color: green; */
    display: flex;
    flex-direction: column;
    margin: 0px 0px;
    margin-left: 20px;
}

.teamMemberLevel h4 {
    font-weight: 600;
}

.teamMemberLevel .editAccess {
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    position: relative;
}

.teamMemberLevel .editAccess1 input::placeholder {
    color: black;
}

.teamMemberLevel .editAccess .editAccess1 {
    width: 100%;
    padding: 0px;

}

.teamMemberLevel .editDropDown {
    width: 100%;
    background-color: white;
    bottom: -170px;
    position: absolute;
}

.teamMemberLevel h4 {
    margin-bottom: 5px;
}

.Toastify__toast-theme--light {
    color: white !important;
}

.Toastify__close-button {
    display: none;
}

/* .Toastify__progress-bar--wrp {
    display: none;
} */

.toastify-icon-color-success {
    color: white !important;
}

.teamMemberLevel button {
    padding: 10px;
    border-radius: 7px;
    border: 1px solid #E4E4E7;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-weight: 500;
}

.teamMemberLevel input::placeholder {
    color: #D4D4D8;
}

.teamMember {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.TeamSend {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px 0px;
}

.TeamSend button {
    background-color: var(--primaryColor);
    padding: 8px 10px;
    border-radius: 8px;
    color: white;
}

.manageMember {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 10px 0px;
}

.manageMember h2 {
    text-transform: uppercase;
}

.manageMember h2 {
    font-size: 13px;
    font-family: 500;
    color: #A1A1AA;
    margin: 10px 0px;
    font-weight: 600;
}

.manageMemberEdit {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--borderColor);
    padding: 10px 0px;
}

.manageMember .editAccess {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    position: relative;

}

.manageMember .editAccess1 {
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    border: 1px solid var(--borderColor);
    border-radius: 8px;
}

.manageMember .editAccess1 button {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    padding: 2px;
    border-radius: 7px;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
}

.manageMember .editAccess1 input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    font-weight: 500;
    font-size: 14px;
}

.manageMember .editAccess1 input::placeholder {
    color: black !important;

}

.checkicon {
    margin-top: 5px;
}

.editIcon {
    display: flex;
    flex-direction: column;
}

.manageMemberEdit .dropHandler {
    width: 180px;
    height: auto;
    padding: 10px;
    z-index: 99;
    border: 1px solid var(--borderColor);
    border-radius: 5px 10px;
    margin-top: 6px;
    top: 110%;
    position: absolute;
    right: 0;
    background-color: white;
}

.editDropDown h3 {
    margin: 7px 0px;
    padding: 5px 10px;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.editDropDown h3 img {
    opacity: 0;
}

.editDropDown h3:hover {
    background-color: #EDE9FE;
    color: #8B5CF6;
    padding: 5px 10px;

}

.editDropDown h3:hover img {
    opacity: 1;
}

.manageMemberEdit .dropHandler h3[title] {
    margin: 7px 0px;
    padding: 5px 10px;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 500;

}

.manageMemberEdit h4 {
    width: 30%;
}

.manageMemberEdit p {
    width: 30%;
    color: #A1A1AA;
}

.manageMemberEdit .dropHandler h3[title] {
    background-color: #FEF2F2;
    color: #F87171;
    padding: 5px 10px;
}

.dropHandler {
    width: 100%;
    padding: 10px;
    position: absolute;
    margin-top: 10px;
    background-color: white;
    border-radius: 8px;
    border: 1px solid var(--borderColor);
}

.dropHandler h3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 4px 0px;
    font-size: 12px;
    font-weight: 500;
    padding: 5px 5px;
    border-radius: 8px;
    transition: 0.5s;
}

.dropHandler h3:hover {
    background-color: #EDE9FE;
    color: #8B5CF6;
}

.dropHandler h3 img {
    opacity: 0;
    transition: 0.5s;
}

.dropHandler h3:hover img {
    opacity: 1;
}

@media only screen and (max-width: 600px) {

    .teamMembers {
        padding-left: 25px;
        padding-right: 25px;
    }

    .teamMember {
        flex-direction: column;
    }

    .teamMemberLevel {
        width: 100%;
        margin-left: 0%;
    }

    .TeamSend {
        margin-top: 15px;
    }
}