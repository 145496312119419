.addMoney {
    padding: 6px 20px;
    border-radius: 30px;
    background-color: var(--primaryColor);
}

.walletBalance {
    padding-bottom: 30px;
}

.addMoney h2 {
    color: white;
}

.walletBalance {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Balance {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Balance h3 {
    font-size: 40px;
    font-weight: 500;
}

.lowBalance {
    font-size: 12px;
    padding: 6px 15px;
    border-radius: 30px;
    background-color: #FEE2E2;
    color: #EF4444;
}

.dollar {
    font-size: 24px;
    font-weight: 400;
    margin-right: 5px;
}

.addMoneyPopupBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    padding-top: 0px;
}

.currentBalancePopup {
    font-size: 12px;
    font-weight: 400;
    margin-top: -10px;
    color: var(--textColor);
}

.addMoneyPopupBody input {
    border: 1px solid var(--borderColor);
    border-radius: 8px;
    padding: 8px;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
}

.addAmount {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.addAmount h5 {
    color: var(--verifyColor);
    margin-left: 10px;
    padding: 6px 12px;
    background-color: var(--lightGreen);
    border-radius: 20px;
}

.savecredit {
    padding: 10px 30px;
    border-radius: 8px;
    color: var(--white);
    border: 1px solid var(--borderColor);
    background-color: var(--primaryColor);
    width: 100%;
}

.addAmount .active {
    color: white;
    margin-left: 10px;
    padding: 6px 12px;
    background-color: #15803D;
    border-radius: 20px;
}

.lowBalanceAlert {
    color: var(--darkred);
}

.lowbalanceIcon {
    color: var(--darkred);
    font-size: 30px;
    margin-right: 10px
}