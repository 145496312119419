.faqheading {
    font-size: 20px;
    color: var(--primaryColor);
    font-weight: 600;
    line-height: 34px;
    margin-bottom: 15px;
    padding-top: 10px;
}

.faqsection {
    font-weight: 400;
    font-size: 17px;
    line-height: 27px;
    color: var(--textColor);
}

.fullsection {
    padding-bottom: 25px;
    border-bottom: 1px solid var(--borderColor);
}

.linktag {
    color: var(--primaryColor);
    text-decoration: underline;
}