.PopupComp {
    padding: 30px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.PopupCompSuccess {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.waricon {
    color: #EF4444;
    font-size: 50px;
}

.successtext {
    color: green;
    font-size: 12px;
    text-align: center;
    font-weight: 700;
    margin-top: 10px;
}

.PopupCompSuccess h1 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #EF4444;
}

.PopupCompSuccess p {
    font-size: 14px;
    font-weight: 400;
    color: var(--textColor);
    padding: 20px 50px;
}

.inputContainer {
    width: 80%;
}

.PopupInput {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.PopupInput h2 {
    font-size: 14px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 10px;
}

.PopupInput input {
    width: 100%;
    border: 1px solid var(--borderColor);
    border-radius: 8px;
    padding: 8px;

}

.PopupRadio {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
}

.PopupRadioLeft {
    display: flex;
    margin-right: 40px;

}

.PopupRadioLeft h4 {
    font-size: 16px;
    font-weight: 500;
    margin-right: 20px;
    margin-left: 10px;

}

.PopupSingleButton {
    padding: 8px 20px;
    background-color: var(--primaryColor);
    border-radius: 8px;
    color: var(--white);
    margin-top: 20px;
    margin-bottom: 20px;
}

.PopupDoubleButton {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.PopupDoubleButtonSkip {
    width: 50%;
    padding: 8px 15px;
    border: 1px solid var(--borderColor);
    color: var(--textDarkColor);
    border-radius: 8px;
    margin-right: 15px;
}

.PopupDoubleButtonSubmit {
    width: 50%;
    padding: 8px 15px;
    border: 1px solid var(--borderColor);
    color: var(--white);
    background-color: var(--primaryColor);
    border-radius: 8px;
}

.PopupDoubleButtonSubmitdisable {
    width: 50%;
    padding: 8px 15px;
    border: 1px solid var(--borderColor);
    color: var(--white);
    background-color: var(--loadingColor);
    border-radius: 8px;
}

.displayNone {
    display: none;
}

.popupClose {
    font-size: 24px;
    position: absolute;
    top: 2%;
    right: 2%;
}