.projectDetails {
    width: 100%;
    height: 100%;
    padding-bottom: 15px;
    position: relative;
}

.innerprojectDetails {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: 1px solid var(--dropBorderColor);
}

.projectDetailsHeadLeft {
    display: flex;
    align-items: center;
    justify-content: center;
}

.projectDetailsHead {
    padding: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    border-bottom: 1px solid var(--dropBorderColor);
    background-color: var(--dropColor);
}

.bottomBorder {
    padding: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    background-color: var(--dropColor);
}

.projectDetailsLeftIcon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.projectDetailsLeftIconSvg {
    margin-right: 10px;
    height: 18px;
}

.projectDetails h1 {
    font-size: 14px;
    font-weight: 600;
    margin-left: 10px;
}

.projectDetailsDesc {
    width: 100%;
    /* height: 300px; */
    background-color: var(--white);
    border-bottom-left-radius: 8px;
    border-bottom-Right-radius: 8px;
    padding: 30px;
}

.projectDetailsDesc h1 {
    font-size: 15px;
    color: var(--textColor);
    margin-bottom: 20px;
    margin-left: 0;
}

.projectDetailsDesc h2 {
    font-size: 20px;
    font-weight: 600;
    opacity: 0.6;
    margin-bottom: 3px;
}

.projectDetailsDescFlex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 5px;
}

.projectDetailsDescFlex h3 {
    font-size: 14px;
    font-weight: 500;
}

.projectDetailsDescFlex h4 {
    font-size: 14px;
    font-weight: 500;
}

.projectDetailsDescFlex p {
    font-size: 14px;
    font-weight: 500;
    opacity: 0.2;
    margin-left: 5px;

}

.projectDetailsDescFlex p {
    font-size: 14px;
    font-weight: 500;
    opacity: 0.2;
    margin-left: 5px;
}

.projectDetailsDescFlexLast {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 30px;
    margin-top: 30px;

}

.projectDetailsDescFlexLast h4 {
    font-size: 14px;
    font-weight: 500;
}

.projectDetailsDescFlexLast p {
    font-size: 14px;
    font-weight: 500;
    opacity: 0.5;
    margin-left: 5px;
}

.projectDetailsHighlight {
    padding: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #F5F3FF;
    border: 1px solid var(--borderColor);
    border-radius: 8px;
    margin-top: 20px;
}

.projectDetailsHighlight p {
    /* width: 80%; */
    font-weight: 400;
    margin-left: 15px;
    font-size: 15px;
    color: var(--textColor);
}

.projectDetailsHighlight img {
    margin-Top: 5px;
}

.projectDetailsDesc h6 {
    font-size: 14px;
    font-weight: 500;
    opacity: 0.2;
    margin-left: 5px;
}

/* overlay */
.projectDetailsOverlay {
    width: 65%;
    height: 80%;
    background-color: white;
    display: flex;
    z-index: 10 !important;
    flex-direction: column;
    /* padding: 10px; */
    border-radius: 12px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow-y: scroll;
}

.projectDetailsOverlay h6 {
    font-size: 16px;
    font-weight: 600;
    padding: 30px;
    opacity: 0.5;
    padding-bottom: 0px
}

.projectDetailsOverlayFlex {
    display: flex;
    align-items: start;
    padding: 30px;
    padding-top: 5px;
}

.projectDetailsOverlayFlexLeft {
    width: 48%;
    margin-right: 2%;
}

.projectDetailsOverlayFlexLeft h2 {
    font-size: 14px;
    font-weight: 600;
    margin-top: 20px;
}

.projectDetailsOverlayFlexLeft input {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    margin-top: 5px;
    border: 1px solid var(--borderColor);
}

.projectDetailsOverlayFlexRight {
    width: 48%;
    height: 100%;
    margin-right: 2%;
}

.projectDetailsOverlayFlexRight h2 {
    font-size: 14px;
    font-weight: 600;
    margin-top: 20px;
}

.skillFlex {
    display: flex;
    align-items: baseline;
    justify-content: space-between
}

.skillFlex h5 {
    font-size: 14px;
    opacity: 0.5;
    font-weight: 500;
}

.projectDetailsOverlayFlexRight textarea {
    width: 100%;
    height: 420px;
    padding: 10px;
    border-radius: 8px;
    margin-top: 5px;
    border: 1px solid var(--borderColor);

}

@media only screen and (max-width: 600px) {
    .projectDetailsOverlay {
        width: 100%;
        height: 100%;
    }

    .innerprojectDetailsOverlay {
        height: 17%;
        overflow-y: scroll;
        border-radius: 8px;
        border: 1px solid var(--dropBorderColor);
    }

    .projectDetailsOverlay h6 {
        padding: 5px 20px;
    }

    .projectDetailsOverlayFlex {
        flex-direction: column;
        overflow-y: scroll;
        padding: 20px;
        padding-top: 0px;
    }

    .projectDetailsOverlayFlexLeft {
        width: 100%;
        margin-right: 0px;
    }

    .projectDetailsOverlayFlexRight {
        width: 100%;
        margin-left: 0px;
    }

    .projectDetailsOverlayFlexRight textarea {
        height: 200px;
    }

}