/* candidate profile  */
.candidateProfile {
    width: 100%;
    height: 100%;
    padding-right: 100px;
    padding-top: 30px;
}

.candidateProfile1 {
    width: 100%;
    height: 100%;
    /* padding-right: 100px; */
    /* padding-top: 30px; */
    padding-bottom: 30px;
}

.candidateBack {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    gap: 0.5rem;
    cursor: pointer;
}

.disNone {
    display: none;
}

.candidateBack img {
    margin-right: 15px;
    height: 20px;
}

.candidateBack h2 {
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}

.mainProfile {
    width: 100%;
    margin-top: 25px;
    background-color: var(--white);
    border-radius: 8px;
    border: 1px solid var(--borderColor);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 40px;
}

.profileLeft {
    width: 30%;
    min-height: 100%;
    height: 100%;
    border-radius: 8px;
    /* border-right: 1px solid var(--borderColor); */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 35px;
    background-color: #f6fafe;
}



.profileLeftTop {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 15px;
    border-bottom: 1px solid var(--borderColor);
    position: relative;
}

.progress-container {
    width: 100%;
    overflow: hidden;
}

progress {
    width: 100%;
    height: 12px;
    /* background-color: var(--primaryColor); */
    border-radius: 20px;
    appearance: none;
}

progress::-webkit-progress-bar {
    background-color: #f0f0f0;
    border-radius: 20px;
}

.progress_bar {
    background-color: red !important;
}

progress.progress_bar1::-webkit-progress-value {
    background-color: var(--primaryColor);
    border-radius: 20px;
}

progress.progress_bar::-webkit-progress-value {
    background-color: #14a6b8;
    border-radius: 20px;
}

.profileLeftTop img {
    height: 120px;
    width: 120px;
    margin-bottom: 10px;
    object-fit: cover;
    border-radius: 50%;
}

.editprofile {
    position: absolute;
    top: 4%;
    right: 27%;
    background-color: var(--primaryColor);
    padding: 6px 6px 7px 5px;
    border-radius: 50%;
    color: white;
    cursor: pointer;
}

.editicon {
    font-size: 20px;
}

.profileLeftTop h1 {
    font-size: 20px;
    font-weight: 500;
    padding-top: 10px;
    padding-bottom: 10px;
}

.profileLeftTop h2 {
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 10px;
}


.available {
    display: flex;
    padding-bottom: 10px;
    align-items: center;
    justify-content: center;
}

.available p {
    font-size: 12px;
    color: var(--textColor);
}

.available h5 {
    font-size: 15px;
    font-weight: 600;
    margin-left: 5px;

}

.profileLeftMiddle h3 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 14px;
    color: var(--textColor);
}

.proSkills {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 20px;



}

.skillOne {
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #e5dfff; */
    background-color: #E9E9FF;
    color: var(--primaryColor);
    margin-right: 10px;
    border-radius: 20px;
    padding: 3px 5px;
    margin-top: 10px;
}

.skillOne {
    font-size: 10px;
}

.skillOne p {
    font-size: 13px;
    margin-left: 4px;
}

.proExperienceWrap {
    padding-bottom: 15px;
    border-bottom: 1px solid var(--borderColor);
}

.proExperience {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 3px;
    margin-top: 5px;
}

.proExperience h5 {
    font-size: 12px;
    margin-left: 5px;
    color: var(--textColor);

}

.profileLeftBottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.touchButton {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    color: var(--white);
    border-radius: 8px;
    background-color: var(--primaryColor);
    margin-top: 20px;
    margin-bottom: 20px;
}

.touchButtonHire {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    color: var(--white);
    border-radius: 8px;
    background-color: var(--verifyColor);
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 500;
}

.shareButton {
    float: right;
    border-radius: 5px;
    padding: 8px 20px;
    border: 1px solid #8B5CF6;
    margin-bottom: 10px;
    color: #8B5CF6;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    gap: 10px;
    align-items: center;
}

.sendinviteBtn {
    padding: 10px 20px;
    border: 1px solid #8B5CF6;
    background-color: #8B5CF6;
    font-size: 14px;
    border-radius: 5px;
    color: white;
    width: 150px;
    margin-top: 25px;
}

.sendinviteBtn1 {
    padding: 10px 20px;
    border: 1px solid #8B5CF6;
    background-color: #8B5CF6;
    font-size: 14px;
    border-radius: 5px;
    color: white;
    width: 150px;
    /* margin-top: 25px; */
}

.roletext {
    color: #8B5CF6 !important;
    font-weight: 700 !important;
}

.linkdiv {
    background-color: var(--loadingColor);
    display: flex;
    padding: 5px 10px;
    align-items: center;
    border-radius: 5px;
    justify-content: space-between;
    width: 100%;
    margin-top: 7px;
}

.copybtn {
    padding: 8px 15px;
    border: 1px solid #6B7280;
    background-color: white;
    font-size: 12px;
    border-radius: 5px;
    color: black;
    font-weight: 500;
}

.touchButtonnew {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    color: var(--white);
    border-radius: 8px;
    background-color: var(--primaryColor);
    margin-top: 20px;
    margin-bottom: 20px;
}

.touchButtondiable {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    color: var(--white);
    border-radius: 8px;
    /* background-color: #8b5cf678; */
    background-color: var(--loadingColor);
    margin-top: 20px;
    margin-bottom: 20px;
}

.touchButtonHiredisable {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    color: var(--white);
    border-radius: 8px;
    background-color: var(--verifyColor);
    margin-top: 20px;
    margin-bottom: 20px;
    opacity: 0.5;
    cursor: not-allowed;
}

.reserveButton {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    border-radius: 8px;
    border: 1px solid var(--borderColor);
}

.reserveButtondiable {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    border-radius: 8px;
    border: 1px solid var(--borderColor);
    opacity: 0.4;
    cursor: not-allowed;
}

.profileLeftBottom h4 {
    margin-left: 10px;
}

/* candidate profile Right */

.profileRight {
    width: 70%;
    border-radius: 8px;
    height: 100%;
    background-color: #fff;
    padding-top: 0px;
}

.profileRightHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 20px;
    background-color: #f6fafe;
    border-bottom: 1px solid var(--borderColor);
    border-radius: 8px;
    border-bottom-left-radius: 0px;
    /* border-bottom: 2px solid var(--borderColor); */
}

.profileRightHeadH2 {
    padding: 8px 20px;
    /* background-color: var(--primaryColor); */
    color: var(--textColorDark);
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

.profileRightHeadH2Active {
    padding: 8px 20px;
    background-color: var(--primaryColor);
    color: var(--white);
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
}

.profileRightSubHead {
    padding-left: 40px;
    padding-right: 40px;
}

.profileRightHead2 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 10px;
    margin-bottom: 20px;
    /* border-bottom: 2px solid var(--borderColor); */
}

.work {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* padding-bottom: 10px; */
    padding-right: 22px;
    border-bottom: 1.5px solid var(--borderColor);
    gap: 2rem;
}

.workActive {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 10px;
    cursor: pointer;
    border-bottom: 2px solid transparent;
}

.workActiveh5 {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 10px;
    color: var(--primaryColor);
    border-bottom: 2px solid var(--primaryColor);


}

/* .work h5 {
    margin-left: 10px;
    font-size: 14px;
    font-weight: 500;
    margin-right: 10px;
    cursor: pointer;
} */

/* personal */

.personal {
    width: 100%;
    padding: 0px 40px;

}

.personalFlex {
    width: 100%;
    display: flex;
    line-height: 50px;
}

.personalFlexLeft {
    width: 40%;
}

.unreservtext {
    padding: 2rem;
    text-align: center;
}

.personalFlexRight {
    width: 100%;

}

.personal h1 {
    font-size: 15px;
    font-weight: 600;
    /* margin-top: 20px;
        margin-bottom: 20px; */
    color: var(--textColorDark);
}

.personal h2 {
    font-size: 15px;
    font-weight: 400;
    /* margin-top: 20px;
        margin-bottom: 20px; */
    color: var(--textColor);
}

/* profileDetail */
.profileDetails {
    width: 100%;
    padding-bottom: 20px;

}

.profileDetails h1 {
    font-size: 20px;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
}

.profileDetails h2 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
    color: var(--textColorDark);
}

.profileDetails h3 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
    color: var(--textColor);
}

.profileDetails h4 {
    width: 80%;
    font-size: 15px;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 20px;
}

.profileDetails h5 {
    font-size: 14px;
    font-weight: 500;
    opacity: 0.8;
}

.profileDetails h6 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
    color: var(--textColor);
}

.key {
    font-size: 15px;
    font-weight: 600;
}

.vedioNotes {
    display: flex;
    align-items: flex-start;
    background-color: #F5F3FF;
    padding: 15px;
    border-radius: 8px;
}

.vedioNotes img {
    margin-right: 10px;
    margin-top: 5px;
    color: var(--textColor);
}

.notes h4 {
    opacity: 0.6;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 4px;
}

.notes {
    padding-left: 10px;
}

.notes ol {
    list-style: number;
    opacity: 0.6;
    font-weight: 500;
    font-size: 14px;
    padding-left: 20px;
}

/* backgroung */
.backgrundFlexLeft {
    width: 45%;
}

.backgrundFlexRight {
    width: 50%;
}

/* technical */
.technical {
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
}

.video {
    padding: 20px;
    border: 1px solid var(--borderColor);
    border-radius: 8px;
    margin: 20px 0px;
    position: relative;
    width: 100%;
}

.video video {
    width: 100%;
    height: 250px;
    border-radius: 8px;
}

.video img {
    width: 100%;
    height: 220px;
    border-radius: 8px;
    object-fit: cover;
}

.video h1 {
    font-size: 18px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 15px;
}

.video h2 {
    font-size: 16px;
    font-weight: 400;
    color: var(--textColor);
}

.bio {
    margin-top: 30px;
    margin-bottom: 30px;
}

.bio h3 {
    font-size: 14px;
    font-weight: 600;
}

.bio h4 {
    font-size: 14px;
    font-weight: 600;
    margin-top: 5px;
}

/* certificate */
.gradeCertificate1 {
    width: 55%;
    height: 70px;
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
    padding: 10px 12px;
    border-radius: 6px;
    align-items: center;
    border: 1px solid var(--borderColor);
    cursor: pointer;
}

.gradeCertificate1 img {
    height: 40px;
    width: 40px;
    padding: 6px;
    border-radius: 6px;
    border: 1px solid var(--borderColor);
}

.gradeCertificateDesc1 {
    margin-left: 10px;
}

.gradeCertificateDesc1 h6 {
    font-size: 14px;
    font-weight: 600;
    color: var(--textColorDark);

}

.gradeCertificateDesc1 p {
    font-size: 12px;
    font-weight: 400;
    color: var(--textColor);
}

.certification {
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
}

.certify {
    padding: 20px;
    border: 1px solid var(--borderColor);
    border-radius: 8px;
}

.certify h1 {
    font-size: 18px;
    font-weight: 600;
}

.certify h2 {
    font-size: 14px;
    font-weight: 600;
    color: var(--textColor);
}

.date {
    display: flex;
}

.date h3 {
    font-size: 14px;
    color: var(--textColorDark);
    font-weight: 600;
}

.date h4 {
    font-size: 14px;
    color: var(--textColor);
    font-weight: 600;
    margin-left: 3px;
}

.certify h5 {
    font-size: 14px;
    color: var(--textColor);
    font-weight: 600;
    margin-left: 3px;
    margin: 20px 0px;
}

/* hacker */

.hacker {
    width: 100%;
    padding: 0px 40px;
}

.hackerInner {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.hackerInner h1 {
    font-size: 14px;
    font-weight: 600;
}

.hackerInner a {
    font-size: 14px;
    font-weight: 400;
    color: var(--textColor);
}

/* personality */

.personality {
    width: 100%;
    padding: 0px 40px;
}

.personalityTest {
    padding: 20px;
    border: 1px solid var(--borderColor);
    border-radius: 8px;
}

.personalityTest h1 {
    font-size: 16px;
    font-weight: 500;
    color: var(--textColor);
}

/* employment */
.employ {
    width: 100%;
    padding: 0px 40px;
}

.employ h1 {
    font-size: 15px;
    color: var(--textColor);
    margin-bottom: 20px;
    margin-left: 0;
}

.employ h2 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 3px;
    margin-bottom: 10px;
}

.employ h3 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 3px;
    margin-bottom: 10px;
}

.employ h4 {
    font-size: 14px;
    font-weight: 600;
    color: var(--textColor);
    margin-bottom: 3px;
    opacity: 0.7;
    margin-bottom: 10px;
}

.employ h4 {
    font-size: 14px;
    font-weight: 600;
    color: var(--textColor);
    margin-bottom: 3px;
    opacity: 0.7;
    margin-bottom: 10px;
}

.employ h5 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    opacity: 0.7;
    width: 70%;
    line-height: 20px;
}

.employ h6 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 3px;
    margin-bottom: 20px;
    display: flex;
    width: 30%;
}

/* project */
.project {
    padding: 0px 40px;
}

.projectInner h1 {
    font-size: 18px;
    font-weight: 600;
}

.projectFlex {
    display: flex;
    margin-top: 3px;
}

.projectFlex h2 {
    font-size: 14px;
    font-weight: 600;
    /* margin-top: 2px; */

}

.projectFlex h3 {
    font-size: 14px;
    font-weight: 500;
    opacity: 0.7;
    margin-left: 3px;

}

.projectFlex1 {
    display: flex;
    margin: 20px 0px;
}

.projectFlex1 h2 {
    font-size: 14px;
    font-weight: 600;
    margin-top: 2px;

}

.projectFlex1 h3 {
    font-size: 14px;
    font-weight: 500;
    opacity: 0.7;
    margin-left: 3px;

}

.project h4 {
    font-size: 14px;
    font-weight: 600;
    color: var(--textColorDark);
    opacity: 0.9;
}

/* achievements */
.achieve {
    width: 100%;
    padding: 0px 40px;
}

.achieve h2 {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--textColor);
    font-size: 14px;
    font-weight: 400;
}

/* available */
.availableBody {
    width: 100%;
    padding: 0px 40px;
}

.availableFlex {
    display: flex;
    width: 100%;
}

.availableFlexLeft {
    width: 50%;
}

.availableFlexLeft h1 {
    font-size: 14px;
    font-weight: 600;
    margin-top: 30px;
    margin-bottom: 30px;

}

.availableFlexRight h4 {
    font-size: 14px;
    font-weight: 400;
    margin-top: 30px;
    color: var(--textColor);


}

.availableFlexRight {
    width: 57%;
    margin-left: 3%;
}

.availableFlexRight h2 {
    font-size: 14px;
    font-weight: 400;
    margin-top: 30px;
    margin-bottom: 30px;
    color: var(--textColor);

}

/* remote */
.remote {
    width: 100%;
    padding: 0px 40px;
}

.remoteFlex {
    display: flex;
    width: 100%;
}

.remoteFlexLeft {
    width: 25%;
    line-height: 60px;
}

.remoteFlexLeft h1 {
    font-size: 16px;
    font-weight: 600;
}

.remoteFlexRight {
    width: 75%;
    line-height: 60px;
}

.remoteFlexRight h2 {
    font-size: 16px;
    font-weight: 400;
    color: var(--textColor);
}

/* on site */

.onsiteNote {
    padding: 20px;
    border: 1px solid var(--borderColor);
    border-radius: 8px;
    display: flex;
    align-items: flex-start;
    background-color: #F5F3FF;
    margin-top: 20px;

}

.onsiteNote h5 {
    font-size: 16px;
    font-weight: 500;
    color: var(--textColor);
    margin-left: 10px;

}

.onsiteNote h6 {
    font-size: 16px;
    font-weight: 500;
    color: var(--textColor);
    margin-top: 25px;
}

/* travel History */

.travel {
    width: 100%;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 10px;
}

.travelslider {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    margin-left: 40px;

}

.travelslider1 {
    margin: 0px 0px 20px 0px;
    border-radius: 10px;
    padding: 20px;
    margin-right: 20px;
    border: 1px solid #e4e4e7;
}

.travelslider1 h3 {
    font-size: 13px;
    font-weight: 500;
    color: #A1A1AA;
    text-transform: capitalize;
}

.visaStatus {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
}

.visaStatus h1 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
}

.visaStatus h1[title] {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
}

.visaStatus p {
    font-weight: 600;
    font-size: 14px;
    line-height: 25px;
}

.visaStatus p span {
    font-weight: 500;
    font-size: 13px;
    margin-left: 2px;
}

.travelslider1 h4 {
    font-size: 14px;
    font-weight: 400;
    color: #D1D5DB;
    margin-top: 20px;
}

.travelslider::-webkit-scrollbar {
    width: 8px;
}

.travelslider::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #d4d4d8;
}

.width50px {
    width: 50px;
}

@media only screen and (max-width: 600px) {

    .proExperienceWrap {
        padding-bottom: 15px;
        border-bottom: 1px solid var(--borderColor);
    }

    .video img {
        height: 150px;
    }

    .employ {
        padding-left: 0%;
        padding-right: 0%;
        padding-top: 20px;
    }
}